import {
    Box, Collapse, FormControl,
    IconButton, InputLabel, MenuItem, Select, TextField,
} from "@mui/material"
import {Close, Delete,} from "@mui/icons-material"
import {useEffect, useState,} from "react"

import 'swiper/css';
import 'swiper/css/pagination';
import {FILE_PATH_CONST} from "../../../utils/constants";
import {FilesUploadForm} from "../common/filesUploadForm";
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';

export const SwiperFormattingForm = ({data, handleOnChange, containerId}) => {

    const [collapse, setCollapse] = useState(false)

    const [images, setImages] = useState(data.images ? data.images : [])
    const [slidesPerView, setSlidesPerView] = useState(data.slidesPerView ? data.slidesPerView : "")
    const [spaceBetween, setSpaceBetween] = useState(data.spaceBetween ? data.spaceBetween : "")
    const [delay, setDelay] = useState(data.delay ? data.delay : "")
    const [effect, setEffect] = useState(data.effect ? data.effect : "fade")

    const handleChangeSlidesPerView = (event) => {
        setSlidesPerView(event.target.value)
    }
    const handleChangeSpaceBetween = (event) => {
        setSpaceBetween(event.target.value)
    }
    const handleChangeDelay = (event) => {
        setDelay(event.target.value)
    }
    const handleChangeEffect = (event) => {
        setEffect(event.target.value)
    }
    const onUploadSuccess = (data) => {
        setImages(current => [...current, data.file])
    }
    const handleOnDelete = () => {
        handleOnChange({})
    }

    useEffect(() => {
        const timer = setTimeout(() => {
                const temp = {
                    id: data.id,
                    images: images,
                    effect: effect,
                    slidesPerView: slidesPerView,
                    spaceBetween: spaceBetween,
                    delay: delay,
                    type: data.type,
                }
                handleOnChange(temp)
            },
            400);
        return () => clearTimeout(timer);

    }, [images, effect, slidesPerView, spaceBetween,
        delay,
    ])

    return (
        <Box
            sx={{
                margin: '0 3px 0 3px',
                padding: '3px',
                border: '1px solid grey',
                borderRadius: "1rem",
            }}>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        size="large"
                        color="red"
                        onClick={() => setCollapse(!collapse)}
                    >
                        {collapse && (
                            <Close/>
                        )}
                        {!collapse && (
                            <SwipeLeftIcon/>
                        )}
                    </IconButton>
                    <IconButton
                        size="large"
                        color="red"
                        onClick={handleOnDelete}
                    >
                        <Delete/>
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        '& .MuiTextField-root': {m: 1, width: '27ch',},
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Slides per view"
                            id="slides-per-view-input"
                            value={slidesPerView}
                            onChange={handleChangeSlidesPerView}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Space Between"
                            id="space-between-input"
                            value={spaceBetween}
                            onChange={handleChangeSpaceBetween}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Delay"
                            id="delay-input"
                            value={delay}
                            onChange={handleChangeDelay}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <FormControl sx={{m: 1, minWidth: '10ch', marginRight: 0}}>
                            <InputLabel id="effect-label">Effect</InputLabel>
                            <Select
                                labelId="effect-label"
                                id="effect-input"
                                defaultValue=""
                                onChange={handleChangeEffect}
                                value={effect ? effect : "fade"}
                                autoWidth
                                label="Effect"
                                size="small"
                            >
                                <MenuItem value={'fade'}>Fade</MenuItem>
                                <MenuItem value={'coverflow'}>Coverflow</MenuItem>
                                <MenuItem value={'flip'}>Flip</MenuItem>
                                <MenuItem value={'cube'}>Cube</MenuItem>
                                <MenuItem value={'cards'}>Cards</MenuItem>
                            </Select>
                        </FormControl>
                        <FilesUploadForm
                            containerId={containerId}
                            path={`${FILE_PATH_CONST.images}/${containerId}`}
                            handleOnUploadSuccess={onUploadSuccess}
                        />
                    </div>
                </Box>
            </Collapse>
        </Box>
    )
}