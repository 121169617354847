import axios from 'axios';

import {API_CONFIGS} from '../../config';
import {BFF_ENDPOINTS} from '../../utils/constants';
import {loginInterceptor} from '../../utils/interceptor';
import {saveToken, signOut, getRefreshToken, getAnRefreshToken, saveAnToken} from '../../utils/token-store';
import {_post} from '../fetch-api';
import {parseJwt} from "../../utils/app-utils";
import {AppContext} from "../../context/appContext";
import {useContext} from "react";

const AN_U = 'deshana_r39';
const AN_P = '5682c019-71bd-4679-9626-40ddc185f946!';

const refreshAccessToken = async () => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.login}`;

    const params = new URLSearchParams();
    params.append('refresh_token', getRefreshToken());
    params.append('grant_type', 'refresh_token');

    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(loginInterceptor, error => {
        Promise.reject(error);
    });
    await axiosInstance
        .post(endpointURL, params)
        .then(response => {
            const data = response.data;
            saveToken(
                data.access_token,
                data.refresh_token,
                data.expires_in,
                data.user_id,
                data.user_type,
                data.company_id
            );
        })
        .catch(err => {
        });
};

const refreshAnAccessToken = async () => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.login}`;

    const params = new URLSearchParams();
    params.append('refresh_token', getAnRefreshToken());
    params.append('grant_type', 'refresh_token');

    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(loginInterceptor, error => {
        Promise.reject(error);
    });
    await axiosInstance
        .post(endpointURL, params)
        .then(response => {
            const data = response.data;
            saveAnToken(
                data.access_token,
                data.refresh_token,
                data.expires_in
            );
        })
        .catch(err => {
        });
};

export const login = (username, password, callback, errorCallback) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.login}`;
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(loginInterceptor, error => {
        Promise.reject(error);
    });

    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', 'password');

    axiosInstance
        .post(endpointURL, params)
        .then(response => {
            const data = response.data;
            let tkn = parseJwt(data.access_token)
            saveToken(
                data.access_token,
                data.refresh_token,
                data.expires_in,
                data.user_id,
                data.user_type,
                data.company_id
            );
            localStorage.setItem('USERNAME', username);
            callback(tkn);
        })
        .catch(err => {
            errorCallback(err.response.data);
        });
};

export const anLogin = (callback, errorCallback) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.login}`;
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(loginInterceptor, error => {
        Promise.reject(error);
    });

    const params = new URLSearchParams();
    params.append('username', AN_U); /// change this
    params.append('password', AN_P);
    params.append('grant_type', 'password');

    axiosInstance
        .post(endpointURL, params)
        .then(response => {
            const data = response.data;
            saveAnToken(
                data.access_token,
                data.refresh_token,
                data.expires_in
            );
            callback(data.user_id);
        })
        .catch(err => {
            errorCallback(err);
        });
};

export const logout = callback => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.logout}`;
    _post(() => {
        signOut();
        callback();
    }, endpointURL);
};

export default {login, logout, refreshAccessToken, refreshAnAccessToken, anLogin};
