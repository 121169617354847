
export const ReportCopyright = ({currentTime}) => {
    const textStyle = {
        margin: 'auto',
        fontSize: '1.3rem'
    }
    const pasadikaStyle = {
        margin: 'auto',
        fontSize: '1.3rem',
        color: 'red'
    }
    const timeStampStyle = {
        margin: 'auto',
        fontSize: '1.3rem',
        color: 'green'
    }

    return (
        <>
            <span style={textStyle}>This report generated by </span>
            <span style={pasadikaStyle}> Pasadika Admin Portal</span>
            <span style={textStyle}> as at: </span>
            <span style={timeStampStyle}> {currentTime}</span>
        </>
    );
}