import PageLayout from "../../../../ui/layout/pageLayout";
import {ExpensesReport} from "./ExpensesReport";

export default function Expenses() {

    return (
        <PageLayout>
            <ExpensesReport/>
        </PageLayout>
    );
}