import { useState } from "react";

import {
    AppBar,
    Box, IconButton,
} from "@mui/material";
import { PageContentWrapContainer } from "../container/pageContentWrapContainer";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { generateUUID } from "../../../utils/app-utils";
import {
    createContentWrapContainer,
    deleteContentWrapContainerById, updatePageContainerOrder
} from "../../../client/contentWrapContainers/call";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {pageContentContainersTemplate, textTemplates} from "../../../utils/temp-configs/pageContentTemplates";


export default function CreateUpdatePageBodyForm(props) {

    const [pageContentWrapContainers, setPageContentWrapContainers] = useState(props.containers);

    const handleOnDeleteContainer = (id) => {
        const tempList = pageContentWrapContainers.filter(item => item !== id);
        deleteContentWrapContainerById((data) => {
            setPageContentWrapContainers(tempList)
            toast(`Container: ${id} was deleted!`,
                {
                    closeOnClick: true,
                    type: "success",
                    theme: "light",
                    icon: <CheckCircleOutlineIcon />
                });
        }, id,
            (err) => {
                toast(`Error: ${err}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon />
                    });
            })
    }

    const handleAddContainer = (index) => {
        const commonProperties = {
            "id": generateUUID(),
            "parentId": props.parentId,
            "parentType": props.parentType,
        }
        const templateProperties = pageContentContainersTemplate.find(obj => {
            return obj.name === "Ghatha Container 2"
        })
        const temp = {
            ...commonProperties,
            ...templateProperties,
        }
        let tempList = [...pageContentWrapContainers]
        tempList.splice(index, 0, temp.id)
        temp.orderedList = tempList
        createContentWrapContainer(
            (data) => {
                setPageContentWrapContainers(tempList)
                toast(`Container: ${temp.id} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon />
                    });
            },
            temp,
            (err) => {
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon />
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon />
                        });
                }
            })
    }

    const handleOnMoveContainer = (id, fromIndex, toIndex) => {
        let tempList = [...pageContentWrapContainers]
        let temp = tempList[fromIndex]
        tempList.splice(fromIndex, 1)
        tempList.splice(toIndex, 0, temp)
        let metaData = {
            parentId: props.parentId,
            parentType: props.parentType,
            orderedList: tempList,
        }
        updatePageContainerOrder((data) => {
            setPageContentWrapContainers(tempList)
        },
            metaData,
            (error) => {

            })
    }

    return (
        <Box>
            <AppBar position="sticky" sx={{
                backgroundColor: props.hasToBeSaved ? "red" : "orange",
                margin: 0,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "center",
                    padding: '1rem'
                }}>
                    <p style={{
                        fontSize: '1.5rem',
                        margin: '0 1rem 0 0',
                    }}>
                        Create/Update Page Body
                    </p>
                </Box>
            </AppBar>
            {pageContentWrapContainers && pageContentWrapContainers.map((container, index) => (
                <Box key={container}>
                    <PageContentWrapContainer
                        containerIndex={index}
                        containerId={container}
                        parentId={props.parentId}
                        parentType={props.parentType}
                        isLastElement={pageContentWrapContainers.length === (index + 1)}
                        isFirstElement={index === 0}
                        onMove={(toIndex) => {
                            handleOnMoveContainer(container, index, toIndex)
                        }}
                        onDelete={() => {
                            handleOnDeleteContainer(container)
                        }}
                    />
                    <Box textAlign='center'>
                        <IconButton
                            aria-label="expand row"
                            size="large"
                            sx={{ marginTop: 1 }}
                            onClick={() => handleAddContainer(index + 1)}
                        >
                            <AddCircleOutlineOutlined fontSize={"large"} />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            {pageContentWrapContainers.length === 0 && (
                <Box textAlign='center'>
                    <IconButton
                        aria-label="expand row"
                        size="large"
                        sx={{ marginTop: 1 }}
                        onClick={() => handleAddContainer(pageContentWrapContainers.length)}
                    >
                        <AddCircleOutlineOutlined fontSize={"large"} />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
}