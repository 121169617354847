import {API_CONFIGS} from '../../config';
import {BFF_ENDPOINTS} from '../../utils/constants';
import { _get, _put, _post ,_delete } from '../fetch-api';

export const searchAuthorities = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.authoritiesSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'createdDate',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createAuthority = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.authorityCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deleteAuthorityById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.authorityDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getAuthorityById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getAuthorityById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};

export const getUserAuthoritySuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getUserAuthoritySuggestions}`;
  _get(callback, endpointURL);
};

export const updateAuthority = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateAuthority}`;
  _put(callback, endpointURL, body, errCallBack);
};


export const deshanaAccessSuggestions =  callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deshanaAccessSuggestions}`;
  _get(callback, endpointURL);
};