export const getGraphOption = (graphData) => {
    return {
        color: ["#da0120"],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#283b56'
                }
            }
        },
        xAxis: {
            type: 'category',
            name: "කාල පරාසය",
            nameGap: 50,
            nameTextStyle: {
                fontSize: '1.3rem',
                align: 'center',
            },
            nameLocation: 'middle',
            data: graphData ? graphData.map((point) => {
                return point.id;
            }) : []
        },
        yAxis: {
            type: 'value',
            name: "වියදම් එකතුව",
            nameGap: 100,
            nameTextStyle: {
                fontSize: '1.3rem',
                align: 'center',
            },
            nameLocation: 'middle',
            axisLabel: {
                formatter: '{value} රු'
            }
        },
        series: [
            {
                data: graphData ? graphData.map((point) => {
                    return point.totalExpense;
                }) : [],
                type: 'bar'
            },
            {
                data: graphData ? graphData.map((point) => {
                    return point.totalExpense;
                }) : [],
                type: 'line',
                smooth: true,
                color: ["#0158da"],
            }
        ],
    };
}

export const getGroupedOptions = (groupedData) => {
    return {
        grid: {
            left: 'right', // Adjust the percentage value to position the chart as desired
            containLabel: true, // Optional, set to true if your chart contains labels
            bottom: '1%',
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'horizontal',
            left: 'left',
            width: 'auto',
            padding: [5, 20],
            itemGap: 20,
            textStyle: {
                fontSize: '1.2rem',
            },
        },
        border: '1px',
        series: [
            {
                name: 'වියදම් ප්‍රස්තාරය',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: true,
                itemStyle: {
                    borderRadius: 5,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                center: ['50%', '60%'],
                label: {
                    show: false,
                    position: 'center',
                    fontSize: '1.2rem',
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '1.8rem',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: true
                },
                data: groupedData ? groupedData.map((data) => {
                    return {value: data.totalExpense, name: data.id}
                }) : [],
            }
        ]
    }
};