import {
    Box, Button, Divider, IconButton,
} from "@mui/material";
import {useEffect, useState} from "react";
import {CommonTextFormattingForm} from "./commonTextFormattingForm";
import {CommonTextListFormattingForm} from "./commonTextListFormattingForm";
import {CommonImagesUploadForm} from "./commonImagesUploadForm";
import {
    updateContentWrapContainer,
    getContentWrapContainerById,
    createContentWrapContainer, updatePageContainerOrder
} from "../../../client/contentWrapContainers/call";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {WrapContainerSettingsBar} from "./wrapContainerSettingsBar";
import {ArrowCircleDown, ArrowCircleUp} from "@mui/icons-material";
import {AddContentButtons} from "../buttons/addContentButtons";
import {
    buttonTemplates,
    imageGridTemplates,
    modelTemplates, pageContentContainersTemplate,
    textListTemplates,
    textTemplates
} from "../../../utils/temp-configs/pageContentTemplates";
import {generateUUID} from "../../../utils/app-utils";
import {CommonButtonFormatting} from "./commonButtonFormatting";
import MainModalDialog from "../modal/mainModal";
import {ModelContentWrapContainer} from "./modelContentWrapContainer";


export const PageContentWrapContainer = ({
                                             containerId,
                                             containerIndex,
                                             onDelete,
                                             onMove,
                                             parentId,
                                             parentType,
                                             isLastElement,
                                             isFirstElement
                                         }) => {

    const [hasToBeSaved, setHasToBeSaved] = useState(false);

    const [contents, setContents] = useState([])
    const [settings, setSettings] = useState({});

    useEffect(() => {
        if (containerId) {
            getContentWrapContainerById(
                result => {
                    setContents(result.content.contents)
                    setSettings(result.content.settings)
                },
                error => {
                },
                containerId,
            );
        }
    }, [])

    const handleOnModifiedContainer = (data) => {
        setSettings(data)
        setHasToBeSaved(true)
    }
    const handleOnDeleteContainer = () => {
        onDelete()
    }
    const handleOnModifiedContent = (data, index) => {
        let temp = [...contents]
        temp.splice(index, 1, data);
        setContents(temp)
        setHasToBeSaved(true)
    }
    const handleOnDeleteContent = (id) => {
        const updatedData = contents.filter(item => item.id !== id);
        setContents(updatedData)
        setHasToBeSaved(true)
    }

    const handleOnContainerSave = () => {
        const temp = {
            "id": containerId,
            "parentId": parentId,
            "parentType": parentType,
            "settings": settings,
            "contents": contents,
        }
        updateContentWrapContainer(
            (data) => {
                setHasToBeSaved(false)
                toast(`Container: ${temp.id} was created!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
            },
            temp,
            (err) => {
                if (err.validationFailures) {
                    err && err.validationFailures.map((e) => {
                        toast(`Error: ${e.code}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    })
                } else {
                    toast(`Error: ${err}`,
                        {
                            closeOnClick: true,
                            type: "error",
                            theme: "light",
                            icon: <ErrorOutlineIcon/>
                        });
                }
            })
    }
    const handleOnMove = (direction) => {
        if ("UP" === direction) {
            onMove(containerIndex - 1)
        } else if ("DOWN" === direction) {
            onMove(containerIndex + 1)
        }
    }

    const handleOnContentOptionSelect = (type, name, index) => {
        let temp = [...contents]
        let content = {}
        if (contents.length === 0) {
            index = 0;
        } else {
            index = index + 1
        }

        var stylesConfig

        // -------------------------------------------
        if (type === "model") {
            stylesConfig = modelTemplates.find(obj => {
                return obj.name === name
            })
            const commonProperties = {
                "id": generateUUID(),
                "parentId": containerId,
                "parentType": "Container",
            }
            const templateProperties = pageContentContainersTemplate.find(obj => {
                return obj.name === "Ghatha Container 2"
            })
            const cntObj = {
                ...commonProperties,
                ...templateProperties,
            }
            createContentWrapContainer(
                (data) => {
                    toast(`Container: ${cntObj.id} was created!`,
                        {
                            closeOnClick: true,
                            type: "success",
                            theme: "light",
                            icon: <CheckCircleOutlineIcon/>
                        });
                    try {
                        const sampleModel = {
                            "id": data.content.id,
                            "type": "MODEL",
                            "parentId": containerId,
                        }
                        content = {...stylesConfig, ...sampleModel}

                        temp.splice(index, 0, content);
                        setContents(temp)
                        setHasToBeSaved(true)
                    } catch (error) {
                        console.log(error)
                    }

                },
                cntObj,
                (err) => {
                    if (err.validationFailures) {
                        err && err.validationFailures.map((e) => {
                            toast(`Error: ${e.code}`,
                                {
                                    closeOnClick: true,
                                    type: "error",
                                    theme: "light",
                                    icon: <ErrorOutlineIcon/>
                                });
                        })
                    } else {
                        toast(`Error: ${err}`,
                            {
                                closeOnClick: true,
                                type: "error",
                                theme: "light",
                                icon: <ErrorOutlineIcon/>
                            });
                    }
                })
        } else {
            if (type === "text") {
                stylesConfig = textTemplates.find(obj => {
                    return obj.name === name
                });
                const sampleText = {
                    "id": generateUUID(),
                    "type": "TEXT",
                };
                content = {...stylesConfig, ...sampleText};
            } else if (type === "list") {
                stylesConfig = textListTemplates.find(obj => {
                    return obj.name === name
                });
                const sampleList = {
                    "id": generateUUID(),
                    "type": "TEXT_LIST",
                    "icon": "",
                    "items": ["l,Hd‚ fhda.dY%u ixia:dj", "l,Hd‚ fhda.dY%u ixia:dj",],
                };
                content = {...stylesConfig, ...sampleList};
            } else if (type === "images") {
                stylesConfig = imageGridTemplates.find(obj => {
                    return obj.name === name
                });
                const sampleImages = {
                    "id": generateUUID(),
                    "images": [],
                    "type": "IMAGES",
                };
                content = {...stylesConfig, ...sampleImages};
            } else if (type === "button") {
                stylesConfig = buttonTemplates.find(obj => {
                    return obj.name === name
                });
                const sampleButton = {
                    "id": generateUUID(),
                    "type": "BUTTON",
                    "title": "mdidÈl"
                };
                content = {...stylesConfig, ...sampleButton};
            }
            temp.splice(index, 0, content);
            setContents(temp)
            setHasToBeSaved(true)
        }
    }


    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelContentWrapContainer, setModelContentWrapContainer] = useState(null);
    const handleModalClose = () => {
        setModalOpen(false)
        setModelContentWrapContainer(null)
    }

    useEffect(() => {
        if (modelContentWrapContainer) {
            setModalOpen(true)
        }
    }, [modelContentWrapContainer]);

    const handleOnMoveContainer = (fromIndex, toIndex) => {
        let tempList = [...contents]
        let temp = tempList[fromIndex]
        tempList.splice(fromIndex, 1)
        tempList.splice(toIndex, 0, temp)
        setContents([...tempList])
        setHasToBeSaved(true)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: settings.position,
            }}
            data-aos={settings.transition}
        >
            <MainModalDialog
                open={modalOpen}
                handleClose={handleModalClose}
                header={""}
                body={modelContentWrapContainer}
                size={'xl'}
            />

            <Box sx={{
                width: settings.width,
                height: settings.height,
                margin: settings.margin,
                padding: settings.padding,
                border: settings.border,
                borderRadius: settings.borderRadius,
                boxShadow: settings.boxShadow,
                position: "relative",
                backgroundImage: settings.backgroundImage,
                backgroundColor: settings.backgroundColor,
                overflow: "visible",
            }}
            >
                {settings && (
                    <WrapContainerSettingsBar
                        handleOnSave={handleOnContainerSave}
                        hasToBeSaved={hasToBeSaved}
                        onDelete={handleOnDeleteContainer}
                        data={settings}
                        containerId={containerId}
                        handleOnFormSubmit={(data) => {
                            handleOnModifiedContainer(data)
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: 'flow-root',
                        padding: '1rem',
                        zIndex: 3,
                        overflow: 'scroll',
                        maxHeight: '55vh',
                    }}
                >
                    {contents.map((item, index) => (
                        <Box key={index}>
                            {("TEXT" === item.type && (
                                <CommonTextFormattingForm
                                    containerId={containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                    setHasToBeSaved={setHasToBeSaved}
                                    onMove={(toIndex) => {
                                        handleOnMoveContainer(index, toIndex)
                                    }}
                                    itemIndex={index}
                                    isLastElement={contents.length === (index + 1)}
                                    isFirstElement={index === 0}
                                />
                            ))}
                            {("IMAGES" === item.type && (
                                <CommonImagesUploadForm
                                    containerId={containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                />
                            ))}
                            {("TEXT_LIST" === item.type && (
                                <CommonTextListFormattingForm
                                    containerId={containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                />
                            ))}
                            {("BUTTON" === item.type && (
                                <CommonButtonFormatting
                                    containerId={containerId}
                                    data={item}
                                    handleOnFormSubmit={(data) => handleOnModifiedContent(data, index)}
                                    handleOnDelete={() => {
                                        handleOnDeleteContent(item.id)
                                    }}
                                />
                            ))}
                            {("MODEL" === item.type && (
                                <Box textAlign='center'>
                                    <Button
                                        variant="outlined"
                                        aria-label="expand row"
                                        size="large"
                                        sx={{marginTop: 1, fontFamily: item.fontFamily, textTransform: 'lowercase',}}
                                        onClick={() => {
                                            setModelContentWrapContainer(<ModelContentWrapContainer
                                                containerId={item.id}
                                                parentId={parentId}
                                                parentType={"Container"}
                                            />)
                                        }}
                                    >
                                        {item.title}
                                    </Button>
                                </Box>
                            ))}
                            <Divider/>
                            <Box sx={{display: 'flex'}} marginTop={'0.1rem'} marginBottom={'1rem'}>
                                <AddContentButtons
                                    index={index}
                                    menuOptions={textTemplates}
                                    title={"Text"}
                                    onSelect={(name) => handleOnContentOptionSelect("text", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={textListTemplates}
                                    title={"List"}
                                    onSelect={(name) => handleOnContentOptionSelect("list", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={imageGridTemplates}
                                    title={"Images"}
                                    onSelect={(name) => handleOnContentOptionSelect("images", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={buttonTemplates}
                                    title={"Button"}
                                    onSelect={(name) => handleOnContentOptionSelect("button", name, index)}
                                />
                                <AddContentButtons
                                    index={index}
                                    menuOptions={modelTemplates}
                                    title={"model"}
                                    onSelect={(name) => handleOnContentOptionSelect("model", name, index)}
                                />
                            </Box>
                        </Box>
                    ))}
                    {contents.length === 0 && (
                        <></>
                    )}
                </Box>
            </Box>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: "center",
                    margin: "auto 1rem auto 1rem",
                }}
            >
                <IconButton
                    disabled={isFirstElement}
                    onClick={() => handleOnMove("UP")}
                >
                    <ArrowCircleUp fontSize={'large'}/>
                </IconButton>
                <IconButton
                    disabled={isLastElement}
                    onClick={() => handleOnMove("DOWN")}
                >
                    <ArrowCircleDown fontSize={'large'}/>
                </IconButton>
            </div>
        </Box>
    );
}