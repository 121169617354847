import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {tableCollapseStyles} from "./style";

export const AuditDataCollapse = ({ createdUser, lastModifiedUser, createdDate, updatedDate }) => {
    const classes = tableCollapseStyles()
    return (
        <>
            <Grid item xs={2} sm={4} md={4}>
                <Typography align="left" variant="body2" gutterBottom
                            component="div"><span className={classes.collapseDataKey}>Created Date: </span> {createdDate}
                </Typography>
            </Grid>
            {updatedDate && (
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span className={classes.collapseDataKey}>Updated date: </span> {updatedDate}
                    </Typography>
                </Grid>
            )}
            {createdUser && (
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span className={classes.collapseDataKey}>Created User: </span> {createdUser.firstName} {createdUser.lastName}
                    </Typography>
                </Grid>
            )}
            {lastModifiedUser && (
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"> <span className={classes.collapseDataKey}>Updated User: </span>
                         {lastModifiedUser.firstName} {lastModifiedUser.lastName}
                    </Typography>
                </Grid>
            )}
        </>
    )
}