import {
    Box,
    Collapse, Divider, FormControl,
    IconButton, InputLabel, MenuItem, Select, TextField,
} from "@mui/material";
import { Close, Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { FilesUploadForm } from "../common/filesUploadForm";
import { FILE_PATH_CONST } from "../../../utils/constants";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

export const WrapImageUploadForm = (props) => {

    const [collapse, setCollapse] = useState(false);

    const [image, setImage] = useState(props.data ? props.data.image : "");
    const [size, setSize] = useState(props.data ? props.data.size : "30%");
    const [borderTop, setBorderTop] = useState(props.data.borderTop ? props.data.borderTop : "none")
    const [borderBottom, setBorderBottom] = useState(props.data.borderBottom ? props.data.borderBottom : "none")
    const [borderLeft, setBorderLeft] = useState(props.data.borderLeft ? props.data.borderLeft : "none")
    const [borderRight, setBorderRight] = useState(props.data.borderRight ? props.data.borderRight : "none")
    const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(props.data.borderTopLeftRadius ? props.data.borderTopLeftRadius : "0px")
    const [borderTopRightRadius, setBorderTopRightRadius] = useState(props.data.borderTopRightRadius ? props.data.borderTopRightRadius : "0px")
    const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(props.data.borderBottomLeftRadius ? props.data.borderBottomLeftRadius : "0px")
    const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(props.data.borderBottomRightRadius ? props.data.borderBottomRightRadius : "0px")


    const handleChangeSize = (event) => {
        setSize(event.target.value);
    }
    const onUploadSuccess = (data) => {
        setImage(data.file)
    }
    const handleChangeBorderTop = (event) => {
        setBorderTop(event.target.value)
    }
    const handleChangeBorderBottom = (event) => {
        setBorderBottom(event.target.value)
    }
    const handleChangeBorderLeft = (event) => {
        setBorderLeft(event.target.value)
    }
    const handleChangeBorderRight = (event) => {
        setBorderRight(event.target.value)
    }
    const handleChangeBorderTopLeftRadius = (event) => {
        setBorderTopLeftRadius(event.target.value)
    }
    const handleChangeBorderTopRightRadius = (event) => {
        setBorderTopRightRadius(event.target.value)
    }
    const handleChangeBorderBottomLeftRadius = (event) => {
        setBorderBottomLeftRadius(event.target.value)
    }
    const handleChangeBorderBottomRightRadius = (event) => {
        setBorderBottomRightRadius(event.target.value)
    }

    const handleOnDelete = () => {
        props.handleOnChange(null)
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            const temp = {
                image: image,
                size: size,
                borderLeft: borderLeft,
                borderRight: borderRight,
                borderBottom: borderBottom,
                borderTop: borderTop,
                borderTopLeftRadius: borderTopLeftRadius,
                borderTopRightRadius: borderTopRightRadius,
                borderBottomLeftRadius: borderBottomLeftRadius,
                borderBottomRightRadius: borderBottomRightRadius,
            }
            props.handleOnChange(temp)
        },
            400);
        return () => clearTimeout(timer);

    }, [image, size, borderLeft, borderRight,
        borderBottom, borderTop, borderTopLeftRadius,
        borderTopRightRadius, borderBottomLeftRadius, borderBottomRightRadius
    ])

    return (
        <Box
            sx={{
                margin: '0 3px 0 3px',
                padding: '3px',
                border: '1px solid grey',
                borderRadius: "1rem",
            }}>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        size="large"
                        color="red"
                        onClick={() => setCollapse(!collapse)}
                    >
                        {collapse && (
                            <Close />
                        )}
                        {!collapse && (
                            <AddPhotoAlternateIcon />
                        )}
                    </IconButton>
                    <IconButton
                        size="large"
                        color="red"
                        onClick={handleOnDelete}
                    >
                        <Delete />
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={collapse} timeout="auto" unmountOnExit>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        '& .MuiTextField-root': { m: 1, width: '27ch', },
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <FilesUploadForm
                            containerId={props.containerId}
                            path={`${FILE_PATH_CONST.images}/${props.containerId}`}
                            handleOnUploadSuccess={onUploadSuccess}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto' } }}
                            id="size-input"
                            label="Size"
                            value={size}
                            onChange={handleChangeSize}
                            style={{ width: '100px' }}
                            size="small"
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            id="border-top-input"
                            label="Border Top"
                            value={borderTop}
                            onChange={handleChangeBorderTop}
                            size="small"
                            style={{ width: '130px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom"
                            id="border-bottom-input"
                            value={borderBottom}
                            onChange={handleChangeBorderBottom}
                            size="small"
                            style={{ width: '130px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Left"
                            id="border-left-input"
                            value={borderLeft}
                            onChange={handleChangeBorderLeft}
                            size="small"
                            style={{ width: '130px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border-Right"
                            id="border-right-input"
                            value={borderRight}
                            onChange={handleChangeBorderRight}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <Divider />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top Left Radius"
                            id="border-top-right-radius-input"
                            value={borderTopLeftRadius}
                            onChange={handleChangeBorderTopLeftRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Top Right Radius"
                            id="border-top-right-radius-input"
                            value={borderTopRightRadius}
                            onChange={handleChangeBorderTopRightRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom Left Radius"
                            id="border-bottom-left-radius-input"
                            value={borderBottomLeftRadius}
                            onChange={handleChangeBorderBottomLeftRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Border Bottom Right Radius"
                            id="border-bottom-right-radius-input"
                            value={borderBottomRightRadius}
                            onChange={handleChangeBorderBottomRightRadius}
                            size="small"
                            style={{ width: '190px' }}
                        />
                    </div>
                </Box>
            </Collapse>
        </Box>
    )
}