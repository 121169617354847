import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {Box, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import * as moment from "moment";
import {useEffect, useState} from "react";
import {getExpensesGraphData} from "../../../../../client/reports/finance";
import {
    getAssociateSuggestions,
    getItemCategoryById,
    getItemCategorySuggestions
} from "../../../../../client/item-categories/call";
import {loadLocationsSuggestions} from "../../../../../client/location/call";
import {getEventSuggestions} from "../../../../../client/events/call";
import {loadFoundationsSuggestions} from "../../../../../client/foundation/call";

export const ReportFilterForm = (props) => {

    const {
        location, setLocation,
        event, setEvent,
        foundation, setFoundation,
        category, setCategory,
        associate, setAssociate,
        subCategory, setSubCategory,
        transactionDateFrom, setTransactionDateFrom,
        transactionDateTo, setTransactionDateTo,
        stepValue, setStepValue,
        groupedBy, setGroupedBy,
        setData,
    } = props;



    const [categorySuggestions, setCategorySuggestions] = useState(['']);
    const [subCategorySuggestions, setSubCategorySuggestions] = useState(['']);
    const [associateSuggestions, setAssociateSuggestions] = useState(['']);
    const [eventSuggestions, setEventSuggestions] = useState(['']);
    const [foundationSuggestions, setFoundationSuggestions] = useState([''])

    const [locationSuggestions, setLocationSuggestions] = useState(['']);

    useEffect(() => {
        loadLocationSuggestionsFunc()
        loadCategorySuggestions()
        loadEventSuggestionsFunc()
        loadFoundationSuggestionsFunc()
    }, [])

    useEffect(() => {
            let params = {
                stepValue: stepValue ? stepValue : "",
                monasteryId: location ? location.id : "",
                eventId: event ? event.id : '',
                foundationId : foundation ? foundation.id : '',
                categoryId: category ? category.id : '',
                associateId: associate ? associate.id : '',
                subCategoryId: subCategory ? subCategory.id : '',
                transactionDateFrom: transactionDateFrom,
                transactionDateTo: transactionDateTo,
                groupedBy: groupedBy,
            }
            getExpensesGraphData((result) => setData(result.content),
                () => {
                },
                params,
            )
        },
        [transactionDateFrom, transactionDateTo, location, event, foundation, category, subCategory, associate, stepValue, groupedBy])

    useEffect(() => {
        if (category && category.id) {
            getItemCategoryById((result) => {
                    setSubCategorySuggestions(result.content ? result.content.subCategories : [])
                },
                (error) => {
                },
                category.id
            );
            loadAssociateSuggestionsFunc(category.type);
        }
    }, [category, location])

    function loadLocationSuggestionsFunc() {
        loadLocationsSuggestions(result => {
            setLocationSuggestions(result.content);
        });
    }
    
    function loadEventSuggestionsFunc() {
        getEventSuggestions(result => {
            setEventSuggestions(result.content);
        });
    }
    
    function loadFoundationSuggestionsFunc() {
        loadFoundationsSuggestions(result => {
            setFoundationSuggestions(result.content);
        });
    }

    function loadCategorySuggestions() {
        getItemCategorySuggestions(result => {
            setCategorySuggestions(result.content);
        });
    }

    function loadAssociateSuggestionsFunc(type) {
        if (!location) {
            return;
        }
        getAssociateSuggestions(result => {
                setAssociateSuggestions(result.content);
            },
            type,
            location.id,
        );
    }

    const handleChangeTransactionDateFrom = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setTransactionDateFrom(moment(val).format('YYYY-MM-DD'));
        } else {
            setTransactionDateFrom(null)
        }
    };
    const handleChangeTransactionDateTo = (value) => {
        let val = null
        if (value) {
            val = value.$d
            setTransactionDateTo(moment(val).format('YYYY-MM-DD'));
        } else {
            setTransactionDateTo(null)
        }
    };
    const handleChangeLocation = (event) => {
        setLocation(locationSuggestions.find((loc) => loc.id === event.target.value));
        setAssociate(null)
    };
    
    const handleChangeFoundation = (event) => {
        setFoundation(foundationSuggestions.find((fnd) => fnd.id === event.target.value));
    };
    
    const handleChangeEvent = (event) => {
        setEvent(eventSuggestions.find((evt) => evt.id === event.target.value));
    };

    const handleChangeCategory = (event) => {
        setCategory(categorySuggestions.find((cat) => cat.id === event.target.value));
        setSubCategory(null)
        setAssociate(null)
    };


    const handleChangeAssociate = (event) => {
        setAssociate(associateSuggestions.find((asscte) => asscte.id === event.target.value));
    };

    const handleChangeSubCategory = (event) => {
        setSubCategory(subCategorySuggestions.find((cat) => cat.id === event.target.value));
    };

    const handleChangeStepValue = (event) => {
        setStepValue(event.target.value);
    };

    const handleChangeGropedBy = (event) => {
        setGroupedBy(event.target.value);
    };

    return (
        <Box
            sx={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '20px',
                position: 'fixed',
                zIndex: 5,
                backgroundColor: '#ffffff',
                width: 'calc(100% - 275px)',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
            <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        type='date'
                        id="transaction-date-from-input"
                        label="Transaction Date From"
                        inputFormat="YYYY-MM-DD"
                        value={transactionDateFrom}
                        onChange={handleChangeTransactionDateFrom}
                        renderInput={(params) =>
                            <TextField
                                size={'small'}
                                sx={{m: 1, width: '17ch'}}
                                {...params}
                            />
                        }

                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        type='date'
                        id="transaction-date-to-input"
                        label="Transaction Date To"
                        inputFormat="YYYY-MM-DD"
                        value={transactionDateTo}
                        onChange={handleChangeTransactionDateTo}
                        renderInput={(params) =>
                            <TextField
                                sx={{m: 1, width: '17ch'}}
                                size={'small'}
                                {...params}
                            />
                        }

                    />
                </LocalizationProvider>
                <FormControl sx={{m: 1, minWidth: '17ch'}}>
                    <InputLabel id="location-label">Location</InputLabel>
                    <Select
                        labelId="location-label"
                        id="location-input"
                        size={'small'}
                        defaultValue=""
                        value={location ? location.id ? location.id : '' : ""}
                        onChange={handleChangeLocation}
                        autoWidth
                        label="Location"
                    >
                        <MenuItem key={"none"} value="">
                            <em>All</em>
                        </MenuItem>
                        {(locationSuggestions) && locationSuggestions.map((location, index) => (
                            <MenuItem key={index} value={location.id}>{location.nameUnicode}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: '17ch'}}>
                    <InputLabel id="event-label">Event</InputLabel>
                    <Select
                        labelId="event-label"
                        id="event-input"
                        size={'small'}
                        defaultValue=""
                        value={event ? event.id ? event.id : '' : ""}
                        onChange={handleChangeEvent}
                        autoWidth
                        label="Event"
                    >
                        <MenuItem key={"none"} value="">
                            <em>All</em>
                        </MenuItem>
                        {(eventSuggestions) && eventSuggestions.map((event, index) => (
                            <MenuItem key={index} value={event.id}>{event.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: '17ch'}}>
                    <InputLabel id="foundation-label">Foundation</InputLabel>
                    <Select
                        labelId="foundation-label"
                        id="foundation-input"
                        size={'small'}
                        defaultValue=""
                        value={foundation ? foundation.id ? foundation.id : '' : ""}
                        onChange={handleChangeFoundation}
                        autoWidth
                        label="Foundation"
                    >
                        <MenuItem key={"none"} value="">
                            <em>All</em>
                        </MenuItem>
                        {(foundationSuggestions) && foundationSuggestions.map((foundation, index) => (
                            <MenuItem key={index} value={foundation.id}>{foundation.nameUnicode}</MenuItem>
                        ))}
                    </Select>
                </FormControl>                
                <FormControl sx={{m: 1, minWidth: '17ch', marginRight: 0}}>
                    <InputLabel id="category-label">Item Category</InputLabel>
                    <Select
                        labelId="category-label"
                        id="category-input"
                        size={'small'}
                        defaultValue=""
                        value={category ? category.id ? category.id : '' : ''}
                        onChange={handleChangeCategory}
                        label="Category"
                    >
                        <MenuItem key={"none"} value="">
                            <em>All</em>
                        </MenuItem>
                        {(categorySuggestions) && categorySuggestions.map((category, index) => (
                            <MenuItem key={index} value={category.id}>{category ? category.name : ''}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {category && category.subCategories && category.subCategories.length > 0 && (
                    <FormControl sx={{m: 1, minWidth: '17ch', marginRight: 0}}>
                        <InputLabel id="sub-category-label">Item sub-category</InputLabel>
                        <Select
                            labelId="sub-category-label"
                            id="sub-category-input"
                            defaultValue=""
                            size={'small'}
                            value={subCategory ? subCategory.id ? subCategory.id : '' : ''}
                            onChange={handleChangeSubCategory}
                            label="Sub-Category"
                        >
                            <MenuItem key={"none"} value="">
                                <em>All</em>
                            </MenuItem>
                            {(subCategorySuggestions) && subCategorySuggestions.map((subCategory, index) => (
                                <MenuItem key={index}
                                          value={subCategory.id}>{subCategory ? subCategory.name : ''}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {category && category.type && category.type !== '' && (
                    <FormControl sx={{m: 1, minWidth: '17ch', marginRight: 0}}>
                        <InputLabel id="associate-label">{category.type.toLowerCase()}</InputLabel>
                        <Select
                            labelId="associate-label"
                            id="associate-input"
                            defaultValue=""
                            size={'small'}
                            value={associate ? associate.id ? associate.id : '' : ''}
                            onChange={handleChangeAssociate}
                            label={`${category.type.toLowerCase()}`}
                        >
                            <MenuItem key={"none"} value="">
                                <em>All</em>
                            </MenuItem>
                            {(associateSuggestions) && associateSuggestions.map((associate, index) => (
                                <MenuItem key={index} value={associate.id}>{associate.identifier}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <FormControl sx={{m: 1, minWidth: '17ch', marginRight: 0}}>
                    <InputLabel id="grouped-by-label">Grouped by</InputLabel>
                    <Select
                        labelId="grouped-by-label"
                        id="grouped-by-input"
                        defaultValue=""
                        value={groupedBy}
                        size={'small'}
                        onChange={handleChangeGropedBy}
                        label="grouped-by"
                    >
                        {location && location.id ? <></> : <MenuItem value={"monasteryId"}>Location</MenuItem>}
                        {event && event.id ? <></> : <MenuItem value={"eventId"}>Event</MenuItem>}
                        {foundation && foundation.id ? <></> : <MenuItem value={"foundationId"}>Foundation</MenuItem>}
                        {category && category.id ? <></> : <MenuItem value={"categoryId"}>Category</MenuItem>}
                        {subCategory && subCategory.id ? <></> :
                            <MenuItem value={"subCategoryId"}>Sub-Category</MenuItem>}
                        {associate && associate.id ? <></> :
                            <MenuItem value={"associateId"}>Associate</MenuItem>}
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: '17ch', marginRight: 0}}>
                    <InputLabel id="step-value-label">Step Value</InputLabel>
                    <Select
                        labelId="step-value-label"
                        id="step-value-input"
                        defaultValue=""
                        value={stepValue}
                        size={'small'}
                        onChange={handleChangeStepValue}
                        label="step-value"
                    >
                        <MenuItem value={"Daily"}>Daily</MenuItem>
                        <MenuItem value={"Weekly"}>Weekly</MenuItem>
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Yearly"}>Yearly</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </Box>
    )
}