import {tableCollapseStyles} from "../../../ui/table/style";
import {Fragment, useEffect, useState} from "react";
import {getPaymentById} from "../../../../client/payments/call";
import {
    Box,
    CircularProgress,
    Collapse,
    IconButton,
    MenuItem,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import {formatAmount} from "../../../../utils/app-utils";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ActionMenu from "../../../ui/menus/actionMenu";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Delete, Edit} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {DetailedView} from "./detailedView";

export const PaymentTableRow = ({row, setPaymentTobeUpdated, deletePayment, handleModalOpen}) => {

    const classes = tableCollapseStyles()
    const [open, setOpen] = useState(false);
    const [payment, setPayment] = useState({});
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);


    useEffect(() => {
        if (open && !payment.id) {
            setIsPaymentLoading(true);
            getPaymentById(
                result => {
                    setPayment(result.content)
                    setIsPaymentLoading(false)
                },
                error => {
                    setIsPaymentLoading(false)
                },
                row.id,
            );
        }
    }, [open])

    return (
        <Fragment>
            <TableRow sx={{
                '& > *': {borderBottom: 'unset'},
                backgroundColor: row.tobeReviewed ? '#ff5c42' : row.isSpecial ? 'rgba(255,145,0,0.8)' : '',
            }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>

                <TableCell align="left">{row.paymentType}</TableCell>
                <TableCell align="left">{row.paymentId}</TableCell>
                <TableCell align="left">{formatAmount(row.amount)}</TableCell>
                <TableCell align="left">{row.transactionDate}</TableCell>
                <TableCell align="left">{row.createdDate}</TableCell>
                <TableCell align="left" onClick={() => {
                    setPaymentTobeUpdated(row.id)
                }}>
                    <ActionMenu>
                        <MenuItem onClick={() => {
                            deletePayment(row.id, row.paymentId)
                        }}>
                            <ListItemIcon>
                                <Delete/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                            <Typography variant="body2" color="text.secondary">
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleModalOpen("updatePayment")
                        }}>
                            <ListItemIcon>
                                <Edit/>
                            </ListItemIcon>
                            <ListItemText>Update</ListItemText>
                            <Typography variant="body2" color="text.secondary">
                            </Typography>
                        </MenuItem>
                    </ActionMenu>
                </TableCell>
            </TableRow>
            <TableRow className={classes.collapseRow}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {isPaymentLoading ? (
                            <Box className={classes.spinnerOuter}>
                                <Box className={classes.spinnerInner}>
                                    <CircularProgress/>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                {payment.id && (
                                    <DetailedView payment={payment} items={row.items}/>
                                )}
                            </>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}