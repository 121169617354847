import {Box, Card, Grid, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import Typography from "@mui/material/Typography";
import {formatAmount} from "../../../../utils/app-utils";
import MainTable from "../../../ui/table/mainTable";
import {tableCollapseStyles} from "../../../ui/table/style";
import {AuditDataCollapse} from "../../../ui/table/auditDataCollapse";

export const DetailedView = ({payment, items}) => {

    const classes = tableCollapseStyles()

    const thead = (<TableHead>
        <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Unit</TableCell>
            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Unit Price</TableCell>
            <TableCell align="left">Amount</TableCell>
        </TableRow>
    </TableHead>)

    const tbody = ((items && items.length > 0) ? (<TableBody>
                {items.map((itemEntry, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{itemEntry.name}</TableCell>
                        <TableCell align="left">{itemEntry.description}</TableCell>
                        <TableCell align="left">{itemEntry.unit}</TableCell>
                        <TableCell align="left">{formatAmount(itemEntry.quantity)}</TableCell>
                        <TableCell align="left">{formatAmount(itemEntry.unitPrice)}</TableCell>
                        <TableCell align="left">{formatAmount(itemEntry.amount)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    return (
        <>
            <Box className={classes.dataContainerBox}>
                <Grid
                    container spacing={{xs: 2, md: 3}}
                    columns={{xs: 4, sm: 8, md: 12}}>
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Payment Type:</span> {payment.paymentType}
                        </Typography>
                    </Grid>
                    {payment.billNo && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Payment No:</span> {payment.billNo}
                            </Typography>
                        </Grid>
                    )}
                    {payment.description && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Description:</span> {payment.description}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div">
                            <span className={classes.collapseDataKey}>Pasadika Payment No:</span> {payment.paymentId}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Total Amount:</span> {formatAmount(payment.amount)}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Transaction Date:</span> {payment.transactionDate}
                        </Typography>
                    </Grid>
                    {payment.vendor && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Vendor:</span> {payment.vendor.name}
                            </Typography>
                        </Grid>
                    )}
                    {payment.discount && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Discount:</span> {formatAmount(payment.discount.amount)}
                            </Typography>
                        </Grid>
                    )}
                    {payment.voucher && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Voucher Number:</span> {payment.voucher.voucherId}
                            </Typography>
                        </Grid>
                    )}
                    {payment.monastery && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Location:</span> {payment.monastery.nameUnicode}
                            </Typography>
                        </Grid>
                    )}
                    {payment.foundation && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Foundation:</span> {payment.foundation.nameUnicode}
                            </Typography>
                        </Grid>
                    )}
                    {payment.event && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Event:</span> {payment.event.title}
                            </Typography>
                        </Grid>
                    )}
                    {payment.category && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Category:</span> {payment.category.name}
                            </Typography>
                        </Grid>
                    )}
                    {payment.subCategory && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>Sub-Category:</span> {payment.subCategory.name}
                            </Typography>
                        </Grid>
                    )}
                    {payment.associate && (<Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div">
                                                            <span
                                                                className={classes.collapseDataKey}>{payment.associate.type.toLowerCase()}:</span> {payment.associate.identifier}
                            </Typography>
                        </Grid>
                    )}
                    <AuditDataCollapse createdDate={payment.createdDate} createdUser={payment.createdUser}
                                       lastModifiedUser={payment.lastModifiedUser} updatedDate={payment.updatedDate}/>
                </Grid>
            </Box>
            {payment.paymentType === 'BILL' && (
                <Card className={classes.collapseTableCard}>
                    <Box className={classes.collapseTableCardContainerBox}>
                        <Typography align="center" variant="h6" gutterBottom
                                    component="div">
                            Item Entries
                        </Typography>
                        <MainTable
                            iscontentLoading={false}
                            colspan={6}
                            tableHeader={thead} tableBody={tbody}
                        />
                    </Box>
                </Card>
            )}
        </>
    )
}