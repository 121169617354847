import {
    Box,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";

export default function MainTable(props) {

    const emptyContent = (
        <TableBody>
            <TableRow
                sx={{
                    '&:last-child td, &:last-child th': {border: 0,},
                    '&:MuiTable-root td' : {fontSize: '1.5rem !important'}
                }}
            >
                <TableCell colSpan={props.colspan ? props.colspan : 5}>
                    <Box
                        sx={{width: '100%', height: "100px"}}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        No content found !
                    </Box>
                </TableCell>
            </TableRow>
        </TableBody>
    )

    const loadingBody = (
        <TableBody>
            <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell colSpan={props.colspan ? props.colspan : 5}>
                    <Box
                        sx={{width: '100%', height: "100px"}}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <CircularProgress/>
                        </Grid>
                    </Box>
                </TableCell>
            </TableRow>
        </TableBody>
    )

    const tableBody = props.tableBody ? props.tableBody : emptyContent;

    return (
        <>
            <TableContainer>
                <Table sx={{border: '5'}} stickyHeader aria-label="sticky table">
                    {props.tableHeader}
                    {!props.iscontentLoading ? tableBody : loadingBody}
                </Table>
            </TableContainer>
        </>)
}



