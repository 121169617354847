import {API_CONFIGS} from "../../config";
import {BFF_ENDPOINTS} from "../../utils/constants";
import {_delete, _get, _post, _put} from "../fetch-api";

export const searchFoundations = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.searchFoundations}`;
    const params = {
        pageNumber,
        pageSize,
        status,
        keyword,
        sortProperty: 'name',
    };
    _get(callback, endpointURL, params, null, errorCallback);
};

export const createFoundation = (callback, data, errCallBack) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.createFoundation}`;
    _post(callback, endpointURL, data, {}, errCallBack);
};

export const updateFoundation = (callback, body, errCallBack) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateFoundation}`;
    _put(callback, endpointURL, body, errCallBack);
};

export const deleteFoundationById = (callback, id, errorCallback) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.deleteFoundationById}/${id}`;
    _delete(callback, endpointURL, errorCallback);
};

export const loadFoundationById = (callback, errorCallback, id) => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadFoundationById}/${id}`;
    _get(callback, endpointURL, {}, null, errorCallback);
};

export const loadFoundationsSuggestions = callback => {
    const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.loadFoundationsSuggestions}`;
    _get(callback, endpointURL);
};
