import MainTable from "../../../../ui/table/mainTable";
import PageLayout from "../../../../ui/layout/pageLayout";
import { TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {formatNumberToReadable} from "../../../../../utils/app-utils";


export const ExpenseTable = ({data, header}) => {

    const tableCellStyle = {
        fontSize: '1.6rem',
        color: 'black',
        textAlign: 'left',
    }

    const tableHeaderCellStyle = {
        fontSize: '1.8rem',
        color: 'black',
        textAlign: 'left',
        fontWeight: 'bold',
    }


    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell sx={tableHeaderCellStyle}>#</TableCell>
            <TableCell sx={tableHeaderCellStyle}>විස්තරය</TableCell>
            <TableCell sx={tableHeaderCellStyle} align="left">එකතුව</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (data && data.length > 0) ? (<TableBody>
                {data.map((item, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell sx={tableCellStyle} align="left">{index + 1}</TableCell>
                        <TableCell sx={tableCellStyle} align="left">{item.id}</TableCell>
                        <TableCell sx={tableCellStyle} align="right">{formatNumberToReadable(item.totalExpense)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    return (
        <div style={{margin: '15px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px'}}>
            <PageLayout>
                <MainTable
                    iscontentLoading={false} tableHeader={tableHeader} tableBody={tableBody}
                />
            </PageLayout>
        </div>
    )
}