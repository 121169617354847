import {Box, Grid,} from "@mui/material";
import Typography from "@mui/material/Typography";
import {tableCollapseStyles} from "../../ui/table/style";
import {AuditDataCollapse} from "../../ui/table/auditDataCollapse";
import {formatAmount} from "../../../utils/app-utils";

export const DetailedView = ({item}) => {

    const classes = tableCollapseStyles()

    return (
        <Box className={classes.dataContainerBox}>
            <Grid
                container spacing={{xs: 2, md: 3}}
                columns={{xs: 4, sm: 8, md: 12}}>
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span
                        className={classes.collapseDataKey}>Id:</span> {item.id}
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span
                        className={classes.collapseDataKey}>Name:</span> {item.name}
                    </Typography>
                </Grid>
                {item.nameUnicode && (
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div"><span
                            className={classes.collapseDataKey}>Name in Unicode:</span> {item.nameUnicode}
                        </Typography>
                    </Grid>
                )}
                {item.nameEnglish && (
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div"><span
                            className={classes.collapseDataKey}>Name in Unicode:</span> {item.nameEnglish}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span
                        className={classes.collapseDataKey}>Is Enabled for Web:</span> {item.enableForWeb ? "Yes" : "No"}
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span
                        className={classes.collapseDataKey}>Status:</span> {item.status}
                    </Typography>
                </Grid>
                {item.shortDescription && (
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div"><span
                            className={classes.collapseDataKey}>Short Description:</span> {item.shortDescription}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span className={classes.collapseDataKey}>Type: </span> {item.type}
                    </Typography>
                </Grid>
                <AuditDataCollapse createdDate={item.createdDate} createdUser={item.createdUser}
                                   lastModifiedUser={item.lastModifiedUser} updatedDate={item.lastModifiedDate}/>
            </Grid>
        </Box>
    )
}