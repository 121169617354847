import PageLayout from "../../../ui/layout/pageLayout";
import {Fragment, useEffect, useState} from "react";
import {deletePaymentById, searchPayments} from "../../../../client/payments/call";
import {
    Card,
    Collapse,
    TableBody,
    TableCell,
    TableHead, TableRow,
} from "@mui/material";
import {Search} from "@mui/icons-material";
import CreatePaymentForm from "../payments/createPaymentForm";
import AddIcon from "@mui/icons-material/Add";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import MainModalDialog from "../../../ui/modal/mainModal";
import MainTable from "../../../ui/table/mainTable";
import Pagination from "../../../ui/pagination/pagination";

import PaymentSearchForm from "./paymentSearchForm";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import Swal from 'sweetalert2'
import {PaymentTableRow} from "./paymentTableRow";

export default function Payments() {

    const [pagination, setPagination] = useState({})

    const [searchFormCollapse, setSearchFormCollapse] = useState(false)

    const [isPaymentsLoading, setIsPaymentsLoading] = useState(true);

    const [paymentsList, setPaymentsList] = useState([]);

    const [searchParams, setSearchParams] = useState({});

    function loadPayments(page, rowsPerPage, params) {
        setIsPaymentsLoading(true)
        searchPayments(
            result => {
                setPaymentsList(result.content);
                setPagination(result.pagination);
                setIsPaymentsLoading(false)
            },
            err => {
                setIsPaymentsLoading(false)
            },
            page ? page : pagination.pageNumber,
            rowsPerPage ? rowsPerPage : pagination.pageSize,
            null,
            params ? params : searchParams,
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(loadPayments, [page, rowsPerPage])

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [paymentTobeUpdated, setPaymentTobeUpdated] = useState('');

    function deletePaymentsFunc(paymentId) {
        deletePaymentById(data => {
                toast(`Payment was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                loadPayments();
            },
            paymentId,
            error => {
                toast(`Error: ${error}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>Collapse</TableCell>
            <TableCell>Payment Type</TableCell>
            <TableCell>Pasadika Payment No</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell align="left">Transaction Date</TableCell>
            <TableCell align="left">Created Date</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    const handleModalOpen = (model) => {
        if (model === 'createPayment') {
            setModelHeader("Create Payment")
            setModelToOpen(createPaymentForm)
        } else if (model === 'updatePayment') {
            setModelHeader("Update Payment")
            setModelToOpen(updatePaymentForm)
        }
        setModalOpen(true);
    }

    const handleModalClose = () => setModalOpen(false);
    const handleSearchFormCollapseClose = () => setSearchFormCollapse(false);
    const handleSearchFormCollapseOpen = () => setSearchFormCollapse(true);

    const createPaymentForm = (
        <CreatePaymentForm
            isUpdate={false}
            handleClose={handleModalClose}
            paymentId={null}
            createOrUpdatecallback={(data) => {
                loadPayments()
                handleModalClose()
            }}
        />
    );

    const updatePaymentForm = (
        <CreatePaymentForm
            isUpdate={true}
            handleClose={handleModalClose}
            paymentId={paymentTobeUpdated}
            createOrUpdatecallback={(data) => {
                loadPayments()
                handleModalClose()
            }
            }
        />
    );

    const floatingActionConfig = [
        {
            title: "Create",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createPayment",
            visible: true,
        },
        {
            title: "Search",
            icon: <Search sx={{mr: 1}}/>,
            onClickAction: handleSearchFormCollapseOpen,
            visible: !searchFormCollapse
        }
    ]

    const deletePayment = (pid, itmId) => {
        Swal.fire({
            icon: 'warning',
            title: `${itmId}`,
            text: `Are you sure you want to delete this item?`,
            showConfirmButton: true,
            showCancelButton: true,
            footer: 'Please double check before accepting this action'
        }).then((value) => {
            if (value.isConfirmed) {
                deletePaymentsFunc(pid)
            }
        })
    }

    const tableBody = (
        (paymentsList && paymentsList.length > 0) ? (
            <TableBody>
                {paymentsList.map((payment, index) => (
                    <PaymentTableRow key={index} row={payment} deletePayment={deletePayment}
                                     setPaymentTobeUpdated={setPaymentTobeUpdated} handleModalOpen={handleModalOpen}/>

                ))}
            </TableBody>
        ) : <></>
    )

    return (
        <PageLayout header="Payments">
            <Collapse in={searchFormCollapse} timeout="auto" unmountOnExit>
                <PaymentSearchForm
                    searchPayments={(params) => {
                        setSearchParams(params)
                        loadPayments(null, null, params)
                    }}
                    closeForm={handleSearchFormCollapseClose}
                />
            </Collapse>
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    size={'lg'}
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainTable
                    iscontentLoading={isPaymentsLoading}
                    colspan={7}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadPayments(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );
}