import {Box, Grid,} from "@mui/material";
import Typography from "@mui/material/Typography";
import {formatAmount} from "../../../../utils/app-utils";
import {tableCollapseStyles} from "../../../ui/table/style";
import {AuditDataCollapse} from "../../../ui/table/auditDataCollapse";

export const DetailedView = ({item}) => {

    const classes = tableCollapseStyles()

    return (
        <Box className={classes.dataContainerBox}>
            <Grid
                container spacing={{xs: 2, md: 3}}
                columns={{xs: 4, sm: 8, md: 12}}>
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span
                        className={classes.collapseDataKey}>Asset Id:</span> {item.assetId}
                    </Typography>
                </Grid>
                {item.description && (
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div"><span
                            className={classes.collapseDataKey}>Description:</span> {item.description}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span
                        className={classes.collapseDataKey}>Total Value:</span> {formatAmount(item.value)}
                    </Typography>
                </Grid>
                {item.vehicleNumber && (
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div"><span
                            className={classes.collapseDataKey}>Vehicle Number:</span> {item.vehicleNumber}
                        </Typography>
                    </Grid>
                )}
                {item.monastery && (
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div"><span
                            className={classes.collapseDataKey}>Location:</span> {item.monastery.nameUnicode}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={2} sm={4} md={4}>
                    <Typography align="left" variant="body2" gutterBottom
                                component="div"><span className={classes.collapseDataKey}>Type:</span> {item.type}
                    </Typography>
                </Grid>
                <AuditDataCollapse createdDate={item.createdDate} createdUser={item.createdUser}
                                   lastModifiedUser={item.lastModifiedUser} updatedDate={item.lastModifiedDate}/>
            </Grid>
        </Box>
    )
}