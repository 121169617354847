const colors = {
    primary: '#344039',
    secondary: '#535945',
    background: '#F2EDE4',
    text: '#8C8069',
    accent: '#808179',
    containerBackgroundColor: '#fffcee',
    cardBorderColor: '#ffdc9d',
    navtext: '#ffffff'
};


const textBase = {
    type: "TEXT",
    text: "mdidÈl fjí wvúh fj; Tnj idorfhka ms<s.ksuq",
    color: colors.primary,
    lineHeight: "2rem",
    fontSize: "1rem",
    font: "FMMalithi",
    textAlign: "justify",
    flexDirection: "",
    letterSpacing: "normal",
    textIndent: "0px",
    wordSpacing: "normal",
    backgroundColor: colors.background,
    margin: "50px auto 10px auto",
    padding: "20px",
    fontStyle: "normal",
    borderLeft: '0px',
    borderRight: '0px',
    borderBottom: '0px',
    borderTop: '0px',
    width: '100%',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
}

const textHeader = {...textBase, ...{font: "FMBasuru", textAlign: "center",}}

const wrapImage = {
    image: 'images/f50ecce9-dfb9-4c2b-a86d-099ecf352531/62124a5c-55e3-43a7-b077-0065310e4673_image-symbol-vector.jpg',
    size: "50%",
    borderLeft: "none",
    borderRight: "10px solid #BDBFB0",
    borderBottom: "none",
    borderTop: "none",
    borderTopLeftRadius: "100px",
    borderTopRightRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "100px"
}

const wrapSwiper = {
    slidesPerView: 3,
    spaceBetween: 30,
    delay: 2500,
    margin: "5px",
    padding: "5px",
    width: '50%',
    borderLeft: "none",
    borderRight: "10px solid #BDBFB0",
    borderBottom: "none",
    borderTop: "none",
    borderTopLeftRadius: "100px",
    borderTopRightRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "100px",
    images: [],
}

const wrapVideo = {
    url: "https://www.youtube.com/embed/aI8vCbPzFGw",
    margin: "5px",
    padding: "5px",
    size: '0.3',
    borderLeft: "none",
    borderBottom: "none",
    borderTop: "none",
    borderRight: "10px solid #BDBFB0",
    borderTopLeftRadius: "100px",
    borderTopRightRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderBottomRightRadius: "100px",
    images: [],
}

export const textTemplates = [
    {
        ...textHeader, ...{
            name: "Header 1",
            fontSize: "4rem",
            color: colors.primary,
        }
    },
    {
        ...textHeader, ...{
            name: "Header 2",
            fontSize: "3rem",
            color: colors.primary,
        }
    },
    {
        ...textHeader, ...{
            type: "TEXT",
            name: "Header 3",
            fontSize: "2.2rem",
            color: colors.text,
        },
    },
    {
        ...textHeader, ...{
            type: "TEXT",
            name: "Header 4",
            fontSize: "1.8rem",
            color: colors.text,
        },
    },
    {
        ...textHeader, ...{
            type: "TEXT",
            name: "Header 5",
            fontSize: "1.4rem",
            color: colors.accent,
        },
    },
    {
        ...textHeader, ...{
            type: "TEXT",
            name: "Header 6",
            fontSize: "1rem",
            color: colors.accent,
        },
    },
    {
        ...textHeader, ...{
            type: "TEXT",
            name: "Subtitle 1",
            fontSize: "1rem",
            color: colors.navtext,
        },
    },
    {
        ...textHeader, ...{
            type: "TEXT",
            name: "Subtitle 2",
            fontSize: "1rem",
            color: colors.accent,
        },
    },
    {
        ...textBase, ...{
            type: "TEXT",
            textIndent: "50px",
            text: `mdidÈl fikiqka moaO;sfha uQ,drïNh iksgqyka jkqfha woaú;Sh we,a,lkao wdrKH fiakdikh;a iu.h' Y%S ,xld rdu[a[ uyd 
                ksldfha Y%S l,Hd‚ fhda.d Y%u ixia:dfõ f.!rjkSh YdLdjla jk we,a,lkao wdrKHh" ud;r lelKÿr m%foaYfha ukrï jk rlaIs;hla 
                weiqre fldgf.k iqjfia jev jikq ,nhs' tla mila úYd, jejlska jg ù we;s fuu wdrKHh" wE; w;S;fha isgu nK Ndjkd lghq;= iy 
                O¾uhg we,aula olajk ieoeyej;=kaf.a buy;a f.!rjhg md;% ù we;'`,
            name: "Paragraph - No Image",
            fontSize: "1.4rem",
            wrapImage: {}
        },
    }, {
        ...textHeader, ...{
            text: "wmmld f; ukqfiiiq fh ckd mdr.dñfkd - w:dhx b;rd mcd ;Srfïjd kqOdj;s",
            name: "Header 2 - Ghatha - Paali",
            fontSize: "2.3rem",
        },
    },
    {
        ...textBase, ...{
            text: `^iir ihqf¾& mrf;rg hkafkda ñksiqka w;frys ÿ¾,N fj;s ^iaj,am fofkls&" wkH jQ jeä fokd fuf;r wkqju ÿj;a`,
            name: "Paragraph - Ghatha - Meaning",
            color: "rgb(221 154 59)",
            lineHeight: "2rem",
            fontSize: "1.4rem",
        },
    },
    {
        ...textBase, ...{
            type: "TEXT",
            textIndent: "50px",
            text: `mdidÈl fikiqka moaO;sfha uQ,drïNh iksgqyka jkqfha woaú;Sh we,a,lkao wdrKH fiakdikh;a iu.h' Y%S ,xld rdu[a[ uyd 
                ksldfha Y%S l,Hd‚ fhda.d Y%u ixia:dfõ f.!rjkSh YdLdjla jk we,a,lkao wdrKHh" ud;r lelKÿr m%foaYfha ukrï jk rlaIs;hla 
                weiqre fldgf.k iq   jfia jev jikq ,nhs' tla mila úYd, jejlska jg ù we;s fuu wdrKHh" wE; w;S;fha isgu nK Ndjkd lghq;= iy 
                O¾uhg we,aula olajk ieoeyej;=kaf.a buy;a f.!rjhg md;% ù we;'`,
            name: "Paragraph - With Image",
            color: "#605849",
            lineHeight: "2rem",
            fontSize: "1.4rem",
            font: "FMMalithi",
            wrapImage: wrapImage
        },
    },
    {
        ...textBase, ...{
            type: "TEXT",
            textIndent: "50px",
            text: `mdidÈl fikiqka moaO;sfha uQ,drïNh iksgqyka jkqfha woaú;Sh we,a,lkao wdrKH fiakdikh;a iu.h' Y%S ,xld rdu[a[ uyd 
                ksldfha Y%S l,Hd‚ fhda.d Y%u ixia:dfõ f.!rjkSh YdLdjla jk we,a,lkao wdrKHh" ud;r lelKÿr m%foaYfha ukrï jk rlaIs;hla 
                weiqre fldgf.k iq   jfia jev jikq ,nhs' tla mila úYd, jejlska jg ù we;s fuu wdrKHh" wE; w;S;fha isgu nK Ndjkd lghq;= iy 
                O¾uhg we,aula olajk ieoeyej;=kaf.a buy;a f.!rjhg md;% ù we;'`,
            name: "Paragraph - With Swiper",
            color: "#605849",
            lineHeight: "2rem",
            fontSize: "1.4rem",
            font: "FMMalithi",
            wrapSwiper: wrapSwiper
        },
    },
    {
        ...textBase, ...{
            type: "TEXT",
            textIndent: "50px",
            text: `mdidÈl fikiqka moaO;sfha uQ,drïNh iksgqyka jkqfha woaú;Sh we,a,lkao wdrKH fiakdikh;a iu.h' Y%S ,xld rdu[a[ uyd 
                ksldfha Y%S l,Hd‚ fhda.d Y%u ixia:dfõ f.!rjkSh YdLdjla jk we,a,lkao wdrKHh" ud;r lelKÿr m%foaYfha ukrï jk rlaIs;hla 
                weiqre fldgf.k iq   jfia jev jikq ,nhs' tla mila úYd, jejlska jg ù we;s fuu wdrKHh" wE; w;S;fha isgu nK Ndjkd lghq;= iy 
                O¾uhg we,aula olajk ieoeyej;=kaf.a buy;a f.!rjhg md;% ù we;'`,
            name: "Paragraph - With Video",
            color: "#605849",
            lineHeight: "2rem",
            fontSize: "1.4rem",
            font: "FMMalithi",
            wrapVideo: wrapVideo
        },
    },
    {
        ...textBase, ...{
            type: "TEXT",
            name: "Swiper - With no Text",
            wrapSwiper: wrapSwiper
        },
    }
]

export const imageGridTemplates = [
    {
        name: "Grid 1",
        caption: {
            color: "grey",
            lineHeight: "normal",
            fontSize: "1rem",
            font: "FMMalithi",
            textAllignment: "center",
            position: "center",
            letterSpacing: "normal",
            text: "mdidÈl fikiqk"
        },
        backgroundColor: "white",
        borderRadius: "5px",
        gap: "5",
        padding: "5px",
        border: "1px solid black",
        boxShadow: "0px 2px 10px rgba(0, 62, 200, 0.5)",
        columns: "3",
        justifyContent: "center",
        alignItems: "center",
    },
]

const textListBase = {
    color: "black",
    lineHeight: "normal",
    fontSize: "1rem",
    font: "FMMalithi",
    textAlign: "center",
    position: "center",
    letterSpacing: "normal",
    wordSpacing: "normal",
    backgroundColor: "white",
    borderRadius: "5px",
    margin: "5px",
    padding: "5px",
    border: "1px solid black",
    fontStyle: "normal",
}

export const textListTemplates = [
    {
        ...textListBase, ...{
            name: "List 1",
            fontSize: "1rem",
        },
    },
]

const buttonBase = {
    type: "BUTTON",
    fontFamily: "FMMalithi",
    color: "warning",
    position: "center",
    margin: "20px auto",
    variant: "outlined",
    size: "medium",
    href: "#",
    title: ";jÿrg;a lshùug''''"
}

export const buttonTemplates = [
    {
        ...buttonBase, ...{
            name: "Outlined 1",
            color: "warning",
            variant: "outlined",
        },
    },
]

export const modelTemplates = [
    {
        type: "MODEL",
        name: "Common 1",
        fontFamily: "FMMalithi",
        size: "medium",
        title: ";jÿrg;a lshùug''''"
    }
]

const pageContentContainersSettingsBase = {
    width: "80%",
    height: "auto",
    margin: "20px auto 0px auto",
    padding: "50px",
    border: "none",
    borderRadius: "15px",
    boxShadow: "none",
    position: "center",
    backgroundColor: "#fffdf6",
    backgroundImage: "none",
    transition: "fade-up",
}

export const pageContentContainersTemplate = [
    {
        type: "TEXT",
        name: "Common 1",
        settings: pageContentContainersSettingsBase,
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 5"
            }),
            textTemplates.find(obj => {
                return obj.name === "Header 2"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Normal - No Image"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Normal"
            }),
        ],
    },
    {
        type: "TEXT",
        name: "CTA 1",
        settings: pageContentContainersSettingsBase,
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 5"
            }),
            textTemplates.find(obj => {
                return obj.name === "Header 2"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Normal - No Image"
            }),
            buttonTemplates.find(obj => {
                return obj.name === "Outlined 1"
            }),
        ],
    },
    {
        type: "TEXT",
        name: "Ghatha Container 1",
        settings: pageContentContainersSettingsBase,
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 2 - Ghatha - Paali"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Ghatha - Meaning"
            }),
        ],
    },
    {
        type: "TEXT",
        name: "Ghatha Container 2",
        settings: pageContentContainersSettingsBase,
        contents: [
            textTemplates.find(obj => {
                return obj.name === "Header 2 - Ghatha - Paali"
            }),
            textTemplates.find(obj => {
                return obj.name === "Paragraph - Ghatha - Meaning"
            }),
        ],
    },
]