import {useEffect, useState} from "react";
import PageLayout from "../../../ui/layout/pageLayout";
import {useNavigate, useParams} from "react-router-dom";
import {deleteItemCategoryById, searchItemCategories} from "../../../../client/item-categories/call";
import {Card, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import CreateItemCategoryForm from "../../finance/itemCategories/createItemCategoryForm";
import AddIcon from "@mui/icons-material/Add";
import FloatingActionButtons from "../../../ui/buttons/floatingActions";
import MainModalDialog from "../../../ui/modal/mainModal";
import MainTable from "../../../ui/table/mainTable";
import Pagination from "../../../ui/pagination/pagination";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Swal from "sweetalert2";
import {ItemCategoryTableRow} from "./itemCategoryTableRow";

export default function ItemCategories() {

    const navigate = useNavigate()
    const params = useParams()

    const [pagination, setPagination] = useState({})

    // loading section
    const [isItemCategoriesLoading, setIsItemCategoriesLoading] = useState(true);

    // itemCategories list
    const [itemCategoriesList, setItemCategoriesList] = useState([]);

    function loadItemCategories(page, rowsPerPage) {
        setIsItemCategoriesLoading(true)
        searchItemCategories(
            result => {
                setItemCategoriesList(result.content);
                setPagination(result.pagination);
                setIsItemCategoriesLoading(false)
            },
            err => {
                setIsItemCategoriesLoading(false)
            },
            page ? page : pagination.pageNumber,
            rowsPerPage ? rowsPerPage : pagination.pageSize,
            null,
            null,
            null
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(loadItemCategories, [page, rowsPerPage])

    // modal related properties
    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [itemCategoryTobeUpdated, setItemCategoryTobeUpdated] = useState('');

    function deleteItemCategoriesFunc(itemCategoryId) {
        deleteItemCategoryById(data => {
                toast(`Item category was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                loadItemCategories();
            },
            itemCategoryId,
            error => {
                toast(`Error: ${error}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const handleModalOpen = (model) => {
        if (model === 'createItemCategory') {
            setModelHeader("Create Item Category")
            setModelToOpen(createItemCategoryForm)
        } else if (model === 'updateItemCategory') {
            setModelHeader("Update Item Category")
            setModelToOpen(updateItemCategoryForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);

    const createItemCategoryForm = (<CreateItemCategoryForm
        isUpdate={false}
        handleClose={handleModalClose}
        itemCategoryId={null}
        createOrUpdatecallback={(data) => {
            loadItemCategories()
            handleModalClose()
        }}
    />);

    const updateItemCategoryForm = (<CreateItemCategoryForm
        isUpdate={true}
        handleClose={handleModalClose}
        itemCategoryId={itemCategoryTobeUpdated}
        createOrUpdatecallback={(data) => {
            loadItemCategories()
            handleModalClose()
        }}
    />);

    const floatingActionConfig = [
        {
            title: "Create",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createItemCategory",
            visible: true,
        }
    ]

    const showDeleteConfirmation = (pid, itmId) => {
        Swal.fire({
            icon: 'warning',
            title: `${itmId}`,
            text: `Are you sure you want to delete this item?`,
            showConfirmButton: true,
            showCancelButton: true,
            footer: 'Please double check before accepting this action'
        }).then((value) => {
            if (value.isConfirmed) {
                deleteItemCategoriesFunc(pid)
            }
        })
    }

    const tableHeader = (<TableHead>
        <TableRow>
            <TableCell>Collapse</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (itemCategoriesList && itemCategoriesList.length > 0) ? (<TableBody>
                {itemCategoriesList.map((itemCategory, index) => (
                    <ItemCategoryTableRow key={index} row={itemCategory} deleteItem={showDeleteConfirmation}
                                   setItemTobeUpdated={setItemCategoryTobeUpdated}
                                   handleModalOpen={handleModalOpen}/>
                ))}
            </TableBody>
        ) : null
    )

    return (
        <PageLayout header="Item Categories">
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainTable
                    iscontentLoading={isItemCategoriesLoading}
                    colspan={7}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadItemCategories(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );
}