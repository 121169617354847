import {Typography} from "@mui/material";

export const ReportHeader = (props) => {
    const {
        stepValue,
        transactionDateFrom,
        transactionDateTo,
        location,
        event,
        foundation,
        category,
        subCategory,
        associate,
        groupedBy,
        headerName,
    } = props;

    const dateStyle = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'Ubuntu',
    };

    const commonStyle = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'Ubuntu',
    };

    const otherStyle = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        fontFamily: 'Ubuntu',
    };

    return (
        <div>
            {location && <Typography sx={commonStyle} >{`${location.nameUnicode}`}</Typography>}
            {foundation && <Typography sx={commonStyle} >{`${foundation.nameUnicode}`}</Typography>}
            {event && <Typography sx={commonStyle} >{`${event.title} වැඩසටහන සඳහා`}</Typography>}
            <Typography sx={dateStyle} >{`${transactionDateFrom} සිට ${transactionDateTo} දක්වා  කාල සීමාව තුල`}</Typography>
            <Typography sx={commonStyle}>{`${headerName}`}</Typography>
            <Typography sx={otherStyle} >{`${category ? category.name + ' සඳහා' : ""}`}</Typography>
            <Typography sx={otherStyle} >{`${subCategory ? subCategory.name + ' සඳහා' : ""} `}</Typography>
            <Typography sx={otherStyle} >{`${associate ? associate.identifier + ' සඳහා' : ""}`}</Typography>
            {groupedBy && <Typography sx={otherStyle} >{`එකතුව: ${(groupedBy === "monasteryId") ? "ස්ථාන අනුව" : (groupedBy === "foundationId") ? "පදනම් අනුව" :
                (groupedBy === "eventId") ? "වැඩසටහ අනුව" : (groupedBy === "categoryId") ? "වර්ගීකරණය අනුව" : (groupedBy === "subCategoryId") ? "උප-වර්ගීකරණය අනුව" :
                    (groupedBy === "associateId") ? "සම්බන්ධිත අයිතම අනුව" : ""}`}</Typography>}
        </div>
    )
}