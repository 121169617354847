import {Box, Card, Grid, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import Typography from "@mui/material/Typography";
import {formatAmount} from "../../../../utils/app-utils";
import {tableCollapseStyles} from "../../../ui/table/style";
import {AuditDataCollapse} from "../../../ui/table/auditDataCollapse";
import MainTable from "../../../ui/table/mainTable";

export const DetailedView = ({item, items}) => {

    const classes = tableCollapseStyles()

    const thead = (<TableHead>
        <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Description</TableCell>
        </TableRow>
    </TableHead>)

    const tbody = ((items && items.length > 0) ? (
            <TableBody>
                {items.map((subCat, index) => (
                    <TableRow
                        key={index}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell align="left">{subCat.name}</TableCell>
                        <TableCell align="left">{subCat.description}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        ) : null
    )

    return (
        <>
            <Box className={classes.dataContainerBox}>
                <Grid
                    container spacing={{xs: 2, md: 3}}
                    columns={{xs: 4, sm: 8, md: 12}}>
                    <Grid item xs={2} sm={4} md={4}>
                        <Typography align="left" variant="body2" gutterBottom
                                    component="div"><span
                            className={classes.collapseDataKey}>Name: </span> {item.name}
                        </Typography>
                    </Grid>
                    {item.description && (
                        <Grid item xs={2} sm={4} md={4}>
                            <Typography align="left" variant="body2" gutterBottom
                                        component="div"><span
                                className={classes.collapseDataKey}>Description:</span> {item.description}
                            </Typography>
                        </Grid>
                    )}
                    <AuditDataCollapse createdDate={item.createdDate} createdUser={item.createdUser}
                                       lastModifiedUser={item.lastModifiedUser} updatedDate={item.lastModifiedDate}/>
                </Grid>
            </Box>
            {item.subCategories && item.subCategories.length > 0 && (
                <Card className={classes.collapseTableCard}>
                    <Box className={classes.collapseTableCardContainerBox}>
                        <Typography align="center" variant="h6" gutterBottom
                                    component="div">
                            Sub-Categories
                        </Typography>
                        <MainTable
                            iscontentLoading={false}
                            colspan={6}
                            tableHeader={thead} tableBody={tbody}
                        />
                    </Box>
                </Card>
            )}
        </>
    )
}