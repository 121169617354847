import {
    Button,
    CardHeader,
    Grid,
} from "@mui/material";
import {Stats} from "../../../../ui/common/reports/stats";
import {ChartContainer} from "../../common/chartContainer";
import ReactECharts from "echarts-for-react";
import {useState} from "react";
import {ExpenseTable} from "./expenseTable";
import {exportToPDF} from "../../../../../utils/exportToPDF";
import {getGraphOption, getGroupedOptions} from "./graphOptions";
import * as moment from "moment/moment";
import {ReportFilterForm} from "./reportFilterForm";
import {ReportCopyright} from "./reportCopyright";
import {ReportHeader} from "./reportHeader";

export const ExpensesReport = () => {

    const [transactionDateFrom, setTransactionDateFrom] = useState(moment(new Date().setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'));
    const [transactionDateTo, setTransactionDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [location, setLocation] = useState(null);
    const [event, setEvent] = useState(null);
    const [foundation, setFoundation] = useState(null);
    const [category, setCategory] = useState(null);
    const [subCategory, setSubCategory] = useState(null);
    const [associate, setAssociate] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);

    const [stepValue, setStepValue] = useState("Monthly");
    const [groupedBy, setGroupedBy] = useState("monasteryId");

    const [graphData, setGraphData] = useState(null);
    const [groupedData, setGroupedData] = useState(null);
    const [summary, setSummary] = useState(null);

    const setData = (data) => {
        setGraphData(data ? data.graphData : null);
        setSummary(data ? data.summary : null);
        setGroupedData(data ? data.groupedData : null);
        setCurrentTime(new Date().toLocaleString())
    }

    return (
        <>
            <ReportFilterForm
                transactionDateFrom={transactionDateFrom}
                setTransactionDateFrom={setTransactionDateFrom}
                transactionDateTo={transactionDateTo}
                setTransactionDateTo={setTransactionDateTo}
                location={location}
                setLocation={setLocation}
                event={event}
                setEvent={setEvent}
                foundation={foundation}
                setFoundation={setFoundation}
                category={category}
                setCategory={setCategory}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                associate={associate}
                setAssociate={setAssociate}
                stepValue={stepValue}
                setStepValue={setStepValue}
                groupedBy={groupedBy}
                setGroupedBy={setGroupedBy}
                setData={setData}
            />
            <div style={{
                margin: '6% 10px 10px 10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '10px',
            }}>
                <CardHeader
                    title="Expense Report"
                />
                <Grid justifyContent="space-around" container direction="row"
                      spacing={{xs: 0,}} alignItems="flex-start">
                    <Grid item xs={3} height={"100%"}>
                        <Stats header={"Total Expenses"} value={summary ? summary.sum : ""} unit={"Rs"}/>
                    </Grid>
                    <Grid item xs={3} height={"100%"}>
                        <Stats header={"Number of transactions"} value={summary ? summary.count : ""} unit={""}/>
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary"
                        onClick={() => exportToPDF("expense-pie-chart", "expense-pie-chart", "landscape")}
                        style={{margin: "20px auto"}}>
                    Export as PDF
                </Button>
                <div id={'expense-pie-chart'} style={{margin: 'auto', justifyContent: 'center', display: 'flex'}}>
                    <ChartContainer>
                        <Grid container style={{
                            height: '100%',
                            width: '100%',
                            padding: '10px 0',
                        }}>
                            <Grid height={'100px'} item xs={12} md={12} lg={12} mb={5}>
                                <ReportHeader
                                    transactionDateFrom={transactionDateFrom}
                                    transactionDateTo={transactionDateTo}
                                    location={location}
                                    setLocation={setLocation}
                                    event={event}
                                    setEvent={setEvent}
                                    foundation={foundation}
                                    setFoundation={setFoundation}
                                    category={category}
                                    subCategory={subCategory}
                                    associate={associate}
                                    groupedBy={groupedBy}
                                    headerName={"සමස්ත වියදම් බෙදීයාම"}
                                />
                            </Grid>
                            <Grid height={'calc(100% - 300px)'} item xs={12} md={12} lg={12}>
                                <ReactECharts style={{
                                    height: '100%',
                                    width: '100%',
                                }} option={getGroupedOptions(groupedData)}/>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <ReportCopyright
                                    currentTime={currentTime}
                                />
                            </Grid>
                        </Grid>
                    </ChartContainer>
                </div>
            </div>
            <div style={{margin: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px',}}>
                <Button variant="contained" color="primary"
                        onClick={() => exportToPDF("total-expense-table", "total-expense-table")}
                        style={{margin: "20px auto"}}>
                    Export as PDF
                </Button>
                <div id={'total-expense-table'}
                     style={{
                         margin: '15px auto',
                         justifyContent: 'center',
                         border: '5px solid #ccc',
                         display: 'flex',
                         borderRadius: '5px',
                         padding: '10px',
                     }}>
                    <Grid container style={{
                        height: '100%',
                        width: '100%',
                        padding: '10px 0',
                    }}>
                        <Grid item xs={12} md={12} lg={12} mb={5}>
                            <ReportHeader
                                transactionDateFrom={transactionDateFrom}
                                transactionDateTo={transactionDateTo}
                                location={location}
                                setLocation={setLocation}
                                event={event}
                                setEvent={setEvent}
                                foundation={foundation}
                                setFoundation={setFoundation}
                                category={category}
                                subCategory={subCategory}
                                associate={associate}
                                groupedBy={groupedBy}
                                headerName={"සමස්ත වියදම් බෙදීයාම"}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <ExpenseTable data={groupedData}/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <ReportCopyright
                                currentTime={currentTime}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div style={{margin: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px',}}>
                <Button variant="contained" color="primary"
                        onClick={() => exportToPDF("expense-bar-chart", "expense-bar-chart", "landscape")}
                        style={{margin: "20px auto"}}>
                    Export as PDF
                </Button>
                <div id={'expense-bar-chart'} style={{margin: 'auto', justifyContent: 'center', display: 'flex'}}>
                    <ChartContainer>
                        <Grid container style={{
                            height: '100%',
                            width: '100%',
                            padding: '10px 0',
                        }}>
                            <Grid item xs={12} md={12} lg={12} mb={5}>
                                <ReportHeader
                                    stepValue={stepValue}
                                    transactionDateFrom={transactionDateFrom}
                                    transactionDateTo={transactionDateTo}
                                    location={location}
                                    setLocation={setLocation}
                                    event={event}
                                    setEvent={setEvent}
                                    foundation={foundation}
                                    setFoundation={setFoundation}
                                    category={category}
                                    subCategory={subCategory}
                                    associate={associate}
                                    headerName={`${(stepValue === "Monthly")? "මාසිකව ": (stepValue === "Weekly")? "සතිපතා ": (stepValue === "Yearly")? "වාර්ෂිකව ": (stepValue === "Daily")? "දෛනිකව ": ""}` +" සමස්ත වියදම් බෙදීයාම"}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <ReactECharts style={{borderRadius: '50px', height: '800px',}}
                                              option={getGraphOption(graphData)}/>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <ReportCopyright
                                    currentTime={currentTime}
                                />
                            </Grid>
                        </Grid>
                    </ChartContainer>
                </div>
            </div>
            <div style={{margin: '10px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px',}}>
                <Button variant="contained" color="primary"
                        onClick={() => exportToPDF("expense-by-timespan", "expense-by-timespan")}
                        style={{margin: "20px auto"}}>
                    Export as PDF
                </Button>
                <div id={'expense-by-timespan'}
                     style={{
                         margin: '15px auto',
                         justifyContent: 'center',
                         border: '5px solid #ccc',
                         display: 'flex',
                         borderRadius: '5px',
                     }}>
                    <Grid container style={{
                        height: '100%',
                        width: '100%',
                        padding: '10px 0',
                    }}>
                        <Grid item xs={12} md={12} lg={12} mb={5}>
                            <ReportHeader
                                stepValue={stepValue}
                                transactionDateFrom={transactionDateFrom}
                                transactionDateTo={transactionDateTo}
                                location={location}
                                setLocation={setLocation}
                                event={event}
                                setEvent={setEvent}
                                foundation={foundation}
                                setFoundation={setFoundation}
                                category={category}
                                subCategory={subCategory}
                                associate={associate}
                                headerName={`${(stepValue === "Monthly")? "මාසිකව ": (stepValue === "Weekly")? "සතිපතා  ": (stepValue === "Yearly")? "වාර්ෂිකව ": (stepValue === "Daily")? "දෛනිකව ": ""}` +" සමස්ත වියදම් බෙදීයාම"}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <ExpenseTable header={`${(stepValue === "Monthly")? "මාසිකව ": (stepValue === "Weekly")? "සතිපතා  ": (stepValue === "Yearly")? "වාර්ෂිකව ": (stepValue === "Daily")? "දෛනිකව ": ""}`} data={graphData}/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <ReportCopyright
                                currentTime={currentTime}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}