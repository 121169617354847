import {
    AppBar,
    Box, Button,
    FormControl, Grid,
    IconButton, ImageListItemBar, InputLabel, MenuItem, Select, TextField,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material"
import {ArrowCircleDown, ArrowCircleUp, Cancel, Delete, Done, Edit} from "@mui/icons-material"
import {useState,} from "react"
import {WrapImageUploadForm} from "./wrapImageUploadForm"
import {WrapVideoAddForm} from "./wrapVideoAddForm"
import {API_CONFIGS} from "../../../config";
import {FontOptionList} from "../common/fontOptionList"
import {MuiColorInput} from "mui-color-input"
import {deleteFile} from "../../../client/fileUpload"
import useDidUpdateEffect from "../../../hooks/commonHooks"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide,} from 'swiper/react';
import {SwiperFormattingForm} from "./swiperFormattingForm";
import {
    Autoplay,
    EffectCards,
    EffectCoverflow,
    EffectCreative,
    EffectCube,
    EffectFade, EffectFlip,
    Pagination
} from "swiper/modules";

export const CommonTextFormattingForm = ({data, handleOnFormSubmit, handleOnDelete, containerId, onMove, itemIndex,isLastElement, isFirstElement}) => {

    const [collapse, setCollapse] = useState(false)

    const [text, setText] = useState(data.text ? data.text : "")
    const [color, setColor] = useState(data.color ? data.color : "")

    const [lineHeight, setLineHeight] = useState(data.lineHeight ? data.lineHeight : "")
    const [fontSize, setFontSize] = useState(data.fontSize ? data.fontSize : "")
    const [font, setFont] = useState(data.font ? data.font : "")
    const [letterSpacing, setLetterSpacing] = useState(data.letterSpacing ? data.letterSpacing : "")
    const [backgroundColor, setBackgroundColor] = useState(data.backgroundColor ? data.backgroundColor : "")
    const [flexDirection, setFlexDirection] = useState(data.flexDirection ? data.flexDirection : "")
    const [wordSpacing, setWordSpacing] = useState(data.wordSpacing ? data.wordSpacing : "")
    const [margin, setMargin] = useState(data.margin ? data.margin : "")
    const [padding, setPadding] = useState(data.padding ? data.padding : "")
    const [borderTop, setBorderTop] = useState(data.borderTop ? data.borderTop : "")
    const [borderBottom, setBorderBottom] = useState(data.borderBottom ? data.borderBottom : "")
    const [borderLeft, setBorderLeft] = useState(data.borderLeft ? data.borderLeft : "")
    const [borderRight, setBorderRight] = useState(data.borderRight ? data.borderRight : "")
    const [borderTopLeftRadius, setBorderTopLeftRadius] = useState(data.borderTopLeftRadius ? data.borderTopLeftRadius : "0px")
    const [borderTopRightRadius, setBorderTopRightRadius] = useState(data.borderTopRightRadius ? data.borderTopRightRadius : "0px")
    const [borderBottomLeftRadius, setBorderBottomLeftRadius] = useState(data.borderBottomLeftRadius ? data.borderBottomLeftRadius : "0px")
    const [borderBottomRightRadius, setBorderBottomRightRadius] = useState(data.borderBottomRightRadius ? data.borderBottomRightRadius : "0px")
    const [width, setWidth] = useState(data.width ? data.width : "")
    const [fontStyle, setFontStyle] = useState(data.fontStyle ? data.fontStyle : "")
    const [textAlign, setTextAlign] = useState(data.textAlign ? data.textAlign : "")
    const [wrapImage, setWrapImage] = useState(data.wrapImage ? data.wrapImage : {})
    const [wrapVideo, setWrapVideo] = useState(data.wrapVideo ? data.wrapVideo : {})
    const [wrapSwiper, setWrapSwiper] = useState(data.wrapSwiper ? data.wrapSwiper : {})
    const [textIndent, setTextIndent] = useState(data.textIndent ? data.textIndent : "")

    const matches = useMediaQuery('(min-width:992px)');

    const handleChangeFontSize = (event) => {
        setFontSize(event.target.value)
    }
    const handleChangeFont = (event) => {
        setFont(event.target.value)
    }
    const handleChangeFontStyle = (event) => {
        setFontStyle(event.target.value)
    }
    const handleChangeLineHeight = (event) => {
        setLineHeight(event.target.value)
    }
    const handleChangeTextAlign = (event) => {
        setTextAlign(event.target.value)
    }
    const handleChangeFlexDirection = (event) => {
        setFlexDirection(event.target.value)
    }
    const handleChangeLetterSpacing = (event) => {
        setLetterSpacing(event.target.value)
    }
    const handleChangeWordSpacing = (event) => {
        setWordSpacing(event.target.value)
    }
    const handleChangeColor = (value) => {
        setColor(value)
    }
    const handleChangeBackgroundColor = (value) => {
        setBackgroundColor(value)
    }
    const handleChangeBorderTop = (event) => {
        setBorderTop(event.target.value)
    }
    const handleChangeBorderBottom = (event) => {
        setBorderBottom(event.target.value)
    }
    const handleChangeBorderLeft = (event) => {
        setBorderLeft(event.target.value)
    }
    const handleChangeBorderRight = (event) => {
        setBorderRight(event.target.value)
    }
    const handleChangeBorderTopLeftRadius = (event) => {
        setBorderTopLeftRadius(event.target.value)
    }
    const handleChangeBorderTopRightRadius = (event) => {
        setBorderTopRightRadius(event.target.value)
    }
    const handleChangeBorderBottomLeftRadius = (event) => {
        setBorderBottomLeftRadius(event.target.value)
    }
    const handleChangeBorderBottomRightRadius = (event) => {
        setBorderBottomRightRadius(event.target.value)
    }
    const handleChangeWidth = (event) => {
        if (event.target.value <= 12 && event.target.value >= 0) {
            setWidth(event.target.value)
        }
    }
    const handleChangeMargin = (event) => {
        setMargin(event.target.value)
    }
    const handleChangePadding = (event) => {
        setPadding(event.target.value)
    }
    const handleChangeText = (event) => {
        setText(event.target.value)
    }
    const handleOnModifiedWrapImage = (data) => {
        setWrapImage(data)
    }
    const handleOnModifiedWrapVideo = (data) => {
        setWrapVideo(data)
    }
    const handleOnModifiedWrapSwiper = (data) => {
        setWrapSwiper(data)
    }
    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: data.id,
                text: text,
                fontSize: fontSize,
                color: color,
                lineHeight: lineHeight,
                font: font,
                textAlign: textAlign,
                flexDirection: flexDirection,
                letterSpacing: letterSpacing,
                wordSpacing: wordSpacing,
                backgroundColor: backgroundColor,
                margin: margin,
                padding: padding,
                fontStyle: fontStyle,
                wrapImage: wrapImage,
                wrapVideo: wrapVideo,
                wrapSwiper: wrapSwiper,
                type: data.type,
                borderLeft: borderLeft,
                borderRight: borderRight,
                borderBottom: borderBottom,
                borderTop: borderTop,
                width: width,
                borderTopLeftRadius: borderTopLeftRadius,
                borderTopRightRadius: borderTopRightRadius,
                borderBottomLeftRadius: borderBottomLeftRadius,
                borderBottomRightRadius: borderBottomRightRadius,
                textIndent: textIndent,
            }
            handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    useDidUpdateEffect(() => {
        const temp = {
            id: data.id,
            text: text,
            fontSize: fontSize,
            color: color,
            lineHeight: lineHeight,
            font: font,
            textAlign: textAlign,
            flexDirection: flexDirection,
            letterSpacing: letterSpacing,
            wordSpacing: wordSpacing,
            backgroundColor: backgroundColor,
            margin: margin,
            padding: padding,
            fontStyle: fontStyle,
            wrapImage: wrapImage,
            wrapVideo: wrapVideo,
            wrapSwiper: wrapSwiper,
            type: data.type,
            borderLeft: borderLeft,
            borderRight: borderRight,
            borderBottom: borderBottom,
            borderTop: borderTop,
            width: width,
            borderTopLeftRadius: borderTopLeftRadius,
            borderTopRightRadius: borderTopRightRadius,
            borderBottomLeftRadius: borderBottomLeftRadius,
            borderBottomRightRadius: borderBottomRightRadius,
            textIndent: textIndent,
        }
        handleOnFormSubmit(temp)
    }, [wrapImage, wrapVideo, wrapSwiper])

    const deleteImage = (path) => {
        deleteFile(() => {
                setWrapImage({});
            },
            path,
            (error) => {
                console.log("file wasn't deleted")
            }
        )
        setWrapImage({});
    }

    const deleteVideo = () => {
        setWrapVideo({});
    }

    const deleteSwiper = () => {
        if (wrapSwiper.images && wrapSwiper.images.length > 0) {
            wrapSwiper.images.forEach((item) => {
                deleteFile(() => {
                    },
                    item,
                    (error) => {
                        console.log("file wasn't deleted")
                    }
                )
            })
        }
        setWrapSwiper({});
    }

    const deleteSwiperImage = (path, index) => {
        deleteFile(() => {
                wrapSwiper.images.splice(index, 1);
                setWrapSwiper({...wrapSwiper});
            },
            path,
            (error) => {
                console.log("file wasn't deleted")
            }
        )
    }

    const handleOnDeleteClick = () => {
        if (wrapImage && wrapImage.image) {
            deleteImage(wrapImage.image)
        }
        deleteSwiper()
        handleOnDelete();
    }

    const handleOnMove = (direction) => {
        if ("UP" === direction) {
            onMove(itemIndex - 1)
        } else if ("DOWN" === direction) {
            onMove(itemIndex + 1)
        }
    }

    return (
        <Box>
            <Grid direction="row" container spacing={2} alignItems={"center"}
                  justifyContent="space-around" sx={{
                flexDirection: `${matches ? "row" : "column"}${flexDirection}`,
                backgroundColor: backgroundColor,
                padding: padding,
                margin: margin,
                width: "100%",
                borderLeft: borderLeft,
                borderRight: borderRight,
                borderBottom: borderBottom,
                borderTop: borderTop,
                borderTopLeftRadius: borderTopLeftRadius,
                borderTopRightRadius: borderTopRightRadius,
                borderBottomLeftRadius: borderBottomLeftRadius,
                borderBottomRightRadius: borderBottomRightRadius,
            }}>
                <Grid item md={1} lg={1}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "center",
                            margin: "auto 1rem auto 1rem",
                        }}
                    >
                        <IconButton
                            disabled={isFirstElement}
                            onClick={() => {handleOnMove("UP")}}
                        >
                            <ArrowCircleUp fontSize={'large'}/>
                        </IconButton>
                        <IconButton
                            disabled={isLastElement}
                            onClick={() => {
                                handleOnMove("DOWN")
                            }}
                        >
                            <ArrowCircleDown fontSize={'large'}/>
                        </IconButton>
                    </div>
                </Grid>
                <Grid item md={11} lg={width-1}>
                    {!collapse && (
                        <Typography
                            sx={{
                                fontSize: fontSize,
                                fontFamily: font,
                                fontStyle: fontStyle,
                                lineHeight: lineHeight,
                                textAlign: textAlign,
                                letterSpacing: letterSpacing,
                                wordSpacing: wordSpacing,
                                color: color,
                                textIndent: textIndent,
                                margin: 'auto',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            {text}
                        </Typography>
                    )}
                    {collapse && (
                        <textarea
                            style={{
                                fontSize: fontSize,
                                fontFamily: font,
                                fontStyle: fontStyle,
                                lineHeight: lineHeight,
                                textAlign: textAlign,
                                letterSpacing: letterSpacing,
                                wordSpacing: wordSpacing,
                                color: color,
                                backgroundColor: backgroundColor,
                                borderLeft: borderLeft,
                                borderRight: borderRight,
                                borderBottom: borderBottom,
                                borderTop: borderTop,
                                width: '100%',
                                textIndent: textIndent,
                            }}
                            type="text"
                            multiple
                            onChange={handleChangeText}
                            value={text}
                        />
                    )}
                </Grid>

                {wrapImage && wrapImage.image && (
                    <Grid item md={11} lg={11 - width}>
                        <img

                            style={{
                                marginBottom: matches ? 0 : '1rem',
                                width: "100%",
                                shapeOutside: `${API_CONFIGS.fileBaseUrl}${wrapImage.image}`,
                                borderLeft: wrapImage.borderLeft,
                                borderRight: wrapImage.borderRight,
                                borderTop: wrapImage.borderTop,
                                borderBottom: wrapImage.borderBottom,
                                borderTopLeftRadius: wrapImage.borderTopLeftRadius,
                                borderTopRightRadius: wrapImage.borderTopRightRadius,
                                borderBottomLeftRadius: wrapImage.borderBottomLeftRadius,
                                borderBottomRightRadius: wrapImage.borderBottomRightRadius,
                            }}
                            src={`${API_CONFIGS.fileBaseUrl}${wrapImage.image}`}
                        />
                        <IconButton
                            sx={{margin: 'auto auto auto 3px'}}
                            onClick={() => deleteImage(wrapImage.image)}
                        >
                            <Delete/>
                        </IconButton>
                    </Grid>
                )}
                {wrapVideo && wrapVideo.url && (
                    <Grid item md={11} lg={11 - width}
                        sx={{
                            padding: "1rem",
                        }}
                    >
                        <iframe
                            src={wrapVideo.url}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                            style={{
                                width: "100%",
                                height: 'auto',
                                aspectRatio: "16 / 9",
                                borderLeft: wrapVideo.borderLeft,
                                borderRight: wrapVideo.borderRight,
                                borderTop: wrapVideo.borderTop,
                                borderBottom: wrapVideo.borderBottom,
                                borderTopLeftRadius: wrapVideo.borderTopLeftRadius,
                                borderTopRightRadius: wrapVideo.borderTopRightRadius,
                                borderBottomLeftRadius: wrapVideo.borderBottomLeftRadius,
                                borderBottomRightRadius: wrapVideo.borderBottomRightRadius,
                            }}
                        >

                        </iframe>
                        <IconButton
                            sx={{margin: 'auto auto auto 3px'}}
                            onClick={() => deleteVideo()}
                        >
                            <Delete/>
                        </IconButton>
                    </Grid>
                )}
                {wrapSwiper && wrapSwiper.images && wrapSwiper.images.length > 0 && (
                    <Grid item md={11} lg={11 - width}>
                        <Grid justifyContent="center" alignItems={"center"} container direction="row">
                            <Grid item sm={11}>
                                <Swiper
                                    effect={"slide"}
                                    slidesPerView={wrapSwiper.slidesPerView}
                                    spaceBetween={wrapSwiper.spaceBetween}
                                    centeredSlides={true}
                                    speed={4000}
                                    freeMode={true}
                                    loop={true}
                                    autoplay={{
                                        delay: Number(wrapSwiper.delay),
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, EffectCards, EffectFade, EffectCoverflow, EffectCreative, EffectCube, EffectFlip]}
                                    style={{borderRadius: '15px'}}
                                >
                                    {wrapSwiper.images && wrapSwiper.images.map((image, index) => (
                                        <SwiperSlide key={index}>
                                            <img src={`${API_CONFIGS.fileBaseUrl}${image}`}
                                                 style={{
                                                     borderRadius: '15px'
                                                 }}
                                                 alt={""}/>
                                            <ImageListItemBar
                                                position={"top"}
                                                title={""}
                                                subtitle={""}
                                                actionIcon={
                                                    <IconButton
                                                        sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                                                        onClick={() => deleteSwiperImage(image, index)}
                                                    >
                                                        <Delete/>
                                                    </IconButton>
                                                }
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </Grid>
                            <Grid item sm={1}>
                                <IconButton
                                    sx={{margin: 'auto auto auto 3px'}}
                                    onClick={() => deleteSwiper()}
                                >
                                    <Delete/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <IconButton
                    size="large"
                    color="red"
                    onClick={() => setCollapse(!collapse)}
                >
                    <Edit/>
                </IconButton>
                <IconButton
                    size="large"
                    color="red"
                    onClick={handleOnDeleteClick}
                >
                    <Delete/>
                </IconButton>
            </Box>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Toolbar>
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="width"
                            id="width-input"
                            type={"number"}
                            value={width}
                            onChange={handleChangeWidth}
                            size="small"
                            style={{width: '100px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            id="font-size-input"
                            label="Size"
                            onChange={handleChangeFontSize}
                            value={fontSize}
                            style={{width: '80px'}}
                            size="small"
                        />
                        <FontOptionList
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            id="font-size-input"
                            label="Font"
                            value={font}
                            onChangeValue={handleChangeFont}
                            style={{width: '80px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Style"
                            id="font-style-input"
                            value={fontStyle}
                            onChange={handleChangeFontStyle}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Height"
                            id="line-height-input"
                            value={lineHeight}
                            onChange={handleChangeLineHeight}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <FormControl sx={{m: 1, minWidth: '10ch', marginRight: 0}}>
                            <InputLabel id="align-label"> Text align</InputLabel>
                            <Select
                                labelId="align-label"
                                id="align-input"
                                defaultValue=""
                                onChange={handleChangeTextAlign}
                                value={textAlign ? textAlign : ""}
                                autoWidth
                                label="Align"
                                size="small"
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                                <MenuItem value={'justify'}>Justified</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{m: 1, minWidth: '10ch', marginRight: 0}}>
                            <InputLabel id="flex-direction-label"> Content Order</InputLabel>
                            <Select
                                labelId="font-label"
                                id="postion-input"
                                defaultValue=""
                                onChange={handleChangeFlexDirection}
                                value={flexDirection}
                                autoWidth
                                label="Content Order"
                                size="small"
                            >
                                <MenuItem value={''}>Left to Right </MenuItem>
                                <MenuItem value={'-reverse'}>Right to Left</MenuItem>
                            </Select>
                        </FormControl>
                    </Toolbar>
                    <Toolbar>
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Letter Spacing"
                            id="letter-spacing-input"
                            value={letterSpacing}
                            onChange={handleChangeLetterSpacing}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Word Spacing"
                            id="word-spacing-input"
                            value={wordSpacing}
                            onChange={handleChangeWordSpacing}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <MuiColorInput
                            format="hex"
                            value={backgroundColor}
                            onChange={handleChangeBackgroundColor}
                            size="small"
                            style={{width: '100px'}}
                        />
                        <MuiColorInput
                            format="hex"
                            value={color}
                            onChange={handleChangeColor}
                            size="small"
                            style={{width: '100px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Top"
                            id="border-top-input"
                            value={borderTop}
                            onChange={handleChangeBorderTop}
                            size="small"
                            style={{width: '100px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Bottom"
                            id="border-bottom-input"
                            value={borderBottom}
                            onChange={handleChangeBorderBottom}
                            size="small"
                            style={{width: '100px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Left"
                            id="border-left-input"
                            value={borderLeft}
                            onChange={handleChangeBorderLeft}
                            size="small"
                            style={{width: '100px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Right"
                            id="border-right-input"
                            value={borderRight}
                            onChange={handleChangeBorderRight}
                            size="small"
                            style={{width: '100px'}}
                        />
                    </Toolbar>
                    <Toolbar>
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Top Left Radius"
                            id="border-top-right-radius-input"
                            value={borderTopLeftRadius}
                            onChange={handleChangeBorderTopLeftRadius}
                            size="small"
                            style={{width: '190px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Top Right Radius"
                            id="border-top-right-radius-input"
                            value={borderTopRightRadius}
                            onChange={handleChangeBorderTopRightRadius}
                            size="small"
                            style={{width: '190px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Bottom Left Radius"
                            id="border-bottom-left-radius-input"
                            value={borderBottomLeftRadius}
                            onChange={handleChangeBorderBottomLeftRadius}
                            size="small"
                            style={{width: '190px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Border Bottom Right Radius"
                            id="border-bottom-right-radius-input"
                            value={borderBottomRightRadius}
                            onChange={handleChangeBorderBottomRightRadius}
                            size="small"
                            style={{width: '190px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Margin"
                            id="margin-input"
                            value={margin}
                            onChange={handleChangeMargin}
                            size="small"
                            style={{width: '80px'}}
                        />
                        <TextField
                            sx={{'& input': {fontFamily: 'Roboto',}}}
                            label="Padding"
                            id="padding-input"
                            value={padding}
                            onChange={handleChangePadding}
                            size="small"
                            style={{width: '80px'}}
                        />
                    </Toolbar>
                    <Toolbar>
                        <WrapImageUploadForm
                            containerId={containerId}
                            data={wrapImage}
                            handleOnChange={(data) => {
                                handleOnModifiedWrapImage(data)
                            }}
                        />
                        <WrapVideoAddForm
                            containerId={containerId}
                            data={wrapVideo}
                            handleOnChange={(data) => {
                                handleOnModifiedWrapVideo(data)
                            }}
                        />
                        <SwiperFormattingForm
                            containerId={containerId}
                            data={wrapSwiper}
                            handleOnChange={(data) => handleOnModifiedWrapSwiper(data)}
                        />
                        <Button
                            sx={{m: 1}}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done/>}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{m: 1}}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel/>}
                        >Cancel</Button>
                    </Toolbar>
                </AppBar>
            )}
        </Box>
    )
}