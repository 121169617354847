import axios from 'axios';
import {defaultInterceptor, openInterceptor, anInterceptor} from '../utils/interceptor';
import Auth from './authentication/auth';
import {login,} from './authentication/auth'

const _get = (callback, endpointURL, params, responseType, errCallBack) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(defaultInterceptor, error => {
        Promise.reject(error);
    });

    axiosInstance
        .get(endpointURL, {params, responseType})
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            if (err.response.status === 401) {
                await Auth.refreshAccessToken();
                axiosInstance
                    .get(endpointURL, {params})
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            login(null, null, null);
                        } else {
                            errCallBack(err.response.data);
                        }
                    });
            }
        });
};

const _get_an = (callback, endpointURL, params, responseType, errCallBack) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(anInterceptor, error => {
        Promise.reject(error);
    });

    axiosInstance
        .get(endpointURL, {params, responseType})
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            if (err.response.status === 401) {
                await Auth.refreshAnAccessToken();
                axiosInstance
                    .get(endpointURL, {params})
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(err => {
                        errCallBack(err.response.data);
                    });
            }
        });
};

const _downloadFile = (callback, endpointURL, params, responseType, errCallBack, onDownloadProgress) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(defaultInterceptor, error => {
        Promise.reject(error);
    });

    axiosInstance
        .get(endpointURL, {
            onDownloadProgress: (progressEvent) => {
                onDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
            params,
            responseType
        })
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            if (err.response.status === 401) {
                await Auth.refreshAccessToken();
                axiosInstance
                    .get(endpointURL, {params})
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            window.location = '/login/index.html';
                        } else {
                            errCallBack(err.response.data);
                        }
                    });
            }
        });
};

const _post = (callback, endpointURL, payload, options, errCallBack) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(defaultInterceptor, error => {
        Promise.reject(error);
    });

    axiosInstance
        .post(endpointURL, payload, options)
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            if (err.response.status === 401) {
                await Auth.refreshAccessToken();
                axiosInstance
                    .post(endpointURL, payload)
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(err_ => {
                        errCallBack(err_.response.data);
                    });
            } else {
                errCallBack(err.response.data);
            }
        });
};

const _put = (callback, endpointURL, payload, errCallBack) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(defaultInterceptor, error => {
        Promise.reject(error);
    });

    axiosInstance
        .put(endpointURL, payload)
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            if (err.response.status === 401) {
                await Auth.refreshAccessToken();
                axiosInstance
                    .put(endpointURL, payload)
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(err_ => {
                        errCallBack(err_.response.data);
                    });
            } else {
                errCallBack(err.response.data);
            }
        });
};

const _delete = (callback, endpointURL, errCallBack, params) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(defaultInterceptor, error => {
        Promise.reject(error);
    });

    axiosInstance
        .delete(endpointURL,{params})
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            if (err.response.status === 401) {
                await Auth.refreshAccessToken();
                axiosInstance
                    .delete(endpointURL)
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(err_ => {
                        errCallBack(err.response.data);
                    });
            } else {
                errCallBack(err.response.data);
            }
        });
};

const _get_open = (callback, endpointURL, params, errorCallback) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(openInterceptor, error => {
        Promise.reject(error);
    });
    axiosInstance
        .get(endpointURL, {params})
        .then(response => {
            callback(response.data);
        })
        .catch(err => {
            errorCallback(err);
        });
};

const _post_open = (callback, endpointURL, payload, options, errCallBack) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(openInterceptor, error => {
        Promise.reject(error);
    });
    axiosInstance
        .post(endpointURL, payload, options)
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            errCallBack(err.response.data);
        });
};

const _put_open = (callback, endpointURL, payload, errCallBack) => {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(openInterceptor, error => {
        Promise.reject(error);
    });
    axiosInstance
        .put(endpointURL, payload)
        .then(response => {
            callback(response.data);
        })
        .catch(async err => {
            if (err.response.status === 401) {
                await Auth.refreshAccessToken();
                axiosInstance
                    .put(endpointURL, payload)
                    .then(response => {
                        callback(response.data);
                    })
                    .catch(err_ => {
                        errCallBack(err_.response.data);
                    });
            } else {
                errCallBack(err.response.data);
            }
        });
};
export {_get, _get_an, _post, _put, _delete, _get_open, _post_open, _put_open, _downloadFile};
