import { API_CONFIGS } from '../../config';
import { BFF_ENDPOINTS } from '../../utils/constants';
import {_get, _put, _post, _delete, _get_open} from '../fetch-api';

export const searchItemCategories = (
    callback,
    errorCallback,
    pageNumber,
    pageSize,
    status,
    keyword
) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.itemCategoriesSearch}`;
  const params = {
    pageNumber,
    pageSize,
    status,
    keyword,
    sortProperty: 'name',
  };
  _get(callback, endpointURL, params, null, errorCallback);
};

export const createItemCategory = (callback, data, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.itemCategoryCreate}`;
  _post(callback, endpointURL, data, {}, errCallBack);
};

export const deleteItemCategoryById = (callback, id, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.itemCategoryDelete}/${id}`;
  _delete(callback, endpointURL, errCallBack);
};

export const getItemCategoryById = (callback, errorCallback, id) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getItemCategoryById}/${id}`;
  _get(callback, endpointURL, {}, null, errorCallback);
};


export const updateItemCategory = (callback, body, errCallBack) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.updateItemCategory}`;
  _put(callback, endpointURL, body, errCallBack);
};

export const getItemCategorySuggestions = callback => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getItemCategorySuggestions}`;
  _get(callback, endpointURL);
};

export const getAssociateSuggestions = (callback, type, locationId) => {
  const endpointURL = `${API_CONFIGS.baseUrl}/${API_CONFIGS.bffContextPath}/${BFF_ENDPOINTS.getAssociateSuggestions}`;
  const params = {
    type: type,
    locationId: locationId,
  };
  _get(callback, endpointURL, params);
};
