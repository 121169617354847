import {Box, CircularProgress, Collapse, IconButton, MenuItem, TableCell, TableRow} from "@mui/material";
import {tableCollapseStyles} from "../../ui/table/style";
import {Fragment, useEffect, useState} from "react";
import ActionMenu from "../../ui/menus/actionMenu";
import ListItemIcon from "@mui/material/ListItemIcon";
import {Delete, Edit} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {loadFoundationById} from "../../../client/foundation/call";
import {DetailedView} from "./detailedView";

export const FoundationTableRow = ({row, setItemTobeUpdated, deleteItem, handleModalOpen}) => {

    const classes = tableCollapseStyles()
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState({});
    const [isItemLoading, setIsItemLoading] = useState(false);

    useEffect(() => {
        if (open && !item.id) {
            setIsItemLoading(true);
            loadFoundationById(
                result => {
                    setItem(result.content)
                    setIsItemLoading(false)
                },
                error => {
                    setIsItemLoading(false)
                },
                row.id,
            );
        }
    }, [open])

    return (
        <Fragment>
            <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left" onClick={() => {
                    setItemTobeUpdated(row.id)
                }}>
                    <ActionMenu>
                        <MenuItem onClick={() => {
                            deleteItem(row.id)
                        }}>
                            <ListItemIcon>
                                <Delete/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                            <Typography variant="body2" color="text.secondary">
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleModalOpen("updateFoundation")
                        }}>
                            <ListItemIcon>
                                <Edit/>
                            </ListItemIcon>
                            <ListItemText>Update</ListItemText>
                            <Typography variant="body2" color="text.secondary">
                            </Typography>
                        </MenuItem>
                    </ActionMenu>
                </TableCell>
            </TableRow>
            <TableRow className={classes.collapseRow}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {isItemLoading ? (
                            <Box className={classes.spinnerOuter}>
                                <Box className={classes.spinnerInner}>
                                    <CircularProgress/>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                {item.id && (
                                    <DetailedView item={item} />
                                )}
                            </>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};