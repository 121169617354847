import {
    AppBar,
    Box, Button,
    FormControl,
    IconButton, InputLabel, MenuItem, Select, TextField,
    Toolbar,
} from "@mui/material";
import { Cancel, Delete, Done, Edit } from "@mui/icons-material";
import { useState } from "react";
import { FontOptionList } from "../common/fontOptionList";

export const CommonButtonFormatting = (props) => {

    const [collapse, setCollapse] = useState(false);

    const [title, setTitle] = useState(props.data.title?props.data.title:"");
    const [fontFamily, setFont] = useState(props.data.fontFamily?props.data.fontFamily:"");
    const [color, setColor] = useState(props.data.color?props.data.color:"");
    const [position, setPosition] = useState(props.data.position?props.data.position:"");
    const [margin, setMargin] = useState(props.data.margin?props.data.margin:"");
    const [variant, setVariant] = useState(props.data.variant?props.data.variant:"");
    const [size, setSize] = useState(props.data.size?props.data.size:"");
    const [href, setHref] = useState(props.data.href?props.data.href:"#");

    const handleChangeTitle = (event) => {
        setTitle(event.target.value);
    };
    const handleChangeFont = (event) => {
        setFont(event.target.value);
    };
    const handleChangeColor = (event) => {
        setColor(event.target.value);
    };
    const handleChangePosition = (event) => {
        setPosition(event.target.value);
    };
    const handleChangeMargin = (event) => {
        setMargin(event.target.value);
    };
    const handleChangeVariant = (event) => {
        setVariant(event.target.value);
    };
    const handleChangeSize = (event) => {
        setSize(event.target.value);
    };
    const handleChangeHref = (event) => {
        setHref(event.target.value);
    };

    const handleOnModified = (isCancel) => {
        if (!isCancel) {
            const temp = {
                id: props.data.id,
                title: title,
                fontFamily: fontFamily,
                color: color,
                position: position,
                margin: margin,
                variant: variant,
                size: size,
                href: href,
                type: props.data.type,
            }
            props.handleOnFormSubmit(temp)
        }
        setCollapse(!collapse)
    }

    return (
        <Box>
            <Box sx={{ display: { xs: 'none', md: 'flex', justifyContent: position, margin: margin } }}>
                <Button sx={{
                    textTransform: 'lowercase',
                    fontFamily: {fontFamily}
                }}
                    color={color}
                    size={size}
                    variant={variant}
                    href={href}
                >{title}</Button>
                <IconButton
                    size="large"
                    color="red"
                    onClick={() => setCollapse(!collapse)}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    size="large"
                    color="red"
                    onClick={props.handleOnDelete}
                >
                    <Delete />
                </IconButton>
            </Box>
            {collapse && (
                <AppBar position="fixed" color="inherit" sx={{
                    top: 'auto', bottom: 0, justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Toolbar>
                        <TextField
                            sx={{ '& input': { fontFamily: {fontFamily}, } }}
                            id="title"
                            label="Title"
                            onChange={handleChangeTitle}
                            value={title}
                            style={{ width: '80px' }}
                            size="small"
                        />
                        <FontOptionList
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            id="font-size-input"
                            label="Font"
                            value={fontFamily}
                            onChangeValue={handleChangeFont}
                            style={{ width: '80px' }}
                        />
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="color-label"> variant</InputLabel>
                            <Select
                                labelId="color-label"
                                id="color-input"
                                defaultValue=""
                                onChange={handleChangeColor}
                                value={color}
                                autoWidth
                                label="color"
                                size="small"
                            >
                                <MenuItem value={'primary'}>primary </MenuItem>
                                <MenuItem value={'secondary'}>secondary</MenuItem>
                                <MenuItem value={'success'}>success</MenuItem>
                                <MenuItem value={'error'}>error</MenuItem>
                                <MenuItem value={'info'}>info</MenuItem>
                                <MenuItem value={'warning'}>warning</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="position-label"> Position</InputLabel>
                            <Select
                                labelId="position-label"
                                id="postion-input"
                                defaultValue=""
                                onChange={handleChangePosition}
                                value={position}
                                autoWidth
                                label="Position"
                                size="small"
                            >
                                <MenuItem value={'center'}>Center </MenuItem>
                                <MenuItem value={'left'}>Left</MenuItem>
                                <MenuItem value={'right'}>Right</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            label="Margin"
                            id="margin-input"
                            value={margin}
                            onChange={handleChangeMargin}
                            size="small"
                            style={{ width: '80px' }}
                        />
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="variant-label"> variant</InputLabel>
                            <Select
                                labelId="variant-label"
                                id="variant-input"
                                defaultValue=""
                                onChange={handleChangeVariant}
                                value={variant}
                                autoWidth
                                label="Variant"
                                size="small"
                            >
                                <MenuItem value={'contained'}>contained </MenuItem>
                                <MenuItem value={'outlined'}>outlined</MenuItem>
                                <MenuItem value={'text'}>text</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: '10ch', marginRight: 0 }}>
                            <InputLabel id="size-label"> Size</InputLabel>
                            <Select
                                labelId="size-label"
                                id="size-input"
                                defaultValue=""
                                onChange={handleChangeSize}
                                value={size}
                                autoWidth
                                label="Size"
                                size="small"
                            >
                                <MenuItem value={'small'}>small </MenuItem>
                                <MenuItem value={'medium'}>medium</MenuItem>
                                <MenuItem value={'large'}>large</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ '& input': { fontFamily: 'Roboto', } }}
                            id="link"
                            label="Link"
                            onChange={handleChangeHref}
                            value={href}
                            style={{ width: '80px' }}
                            size="small"
                        />
                        <Button
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(false)}
                            endIcon={<Done />}
                        >Done</Button>
                        <Button
                            color={'warning'}
                            sx={{ m: 1 }}
                            size="medium"
                            variant="outlined"
                            onClick={() => handleOnModified(true)}
                            endIcon={<Cancel />}
                        >Cancel</Button>
                    </Toolbar>
                </AppBar>
            )}
        </Box>
    )
}