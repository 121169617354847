import {useState, useEffect} from "react";
import PageLayout from "../../ui/layout/pageLayout";
import {toast} from "react-toastify";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {Card, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import CreateFoundationForm from "../foundations/createFoundationForm";
import AddIcon from "@mui/icons-material/Add";
import FloatingActionButtons from "../../ui/buttons/floatingActions";
import MainModalDialog from "../../ui/modal/mainModal";
import MainTable from "../../ui/table/mainTable";
import Pagination from "../../ui/pagination/pagination";
import {deleteFoundationById, searchFoundations} from "../../../client/foundation/call";
import Swal from "sweetalert2";
import {FoundationTableRow} from "./foundationTableRow";

export default function Foundations() {

    const [pagination, setPagination] = useState({})

    const [isFoundationsLoading, setIsFoundationsLoading] = useState(true);

    const [foundationsList, setFoundationsList] = useState([]);

    function loadFoundations(page, rowsPerPage) {
        setIsFoundationsLoading(true)
        searchFoundations(
            result => {
                setFoundationsList(result.content);
                setPagination(result.pagination);
                setIsFoundationsLoading(false)
            },
            err => {
                setIsFoundationsLoading(false)
            },
            page ? page : pagination.pageNumber,
            rowsPerPage ? rowsPerPage : pagination.pageSize,
            null,
            null,
            null
        );
    }

    const {page, rowsPerPage} = pagination
    useEffect(loadFoundations, [page, rowsPerPage])

    const [modalOpen, setModalOpen] = useState(false);
    const [modelToOpen, setModelToOpen] = useState('');
    const [modelHeader, setModelHeader] = useState('');
    const [foundationTobeUpdated, setFoundationTobeUpdated] = useState('');

    function deleteFoundationsFunc(foundationId) {
        deleteFoundationById(data => {
                toast(`Foundation was deleted!`,
                    {
                        closeOnClick: true,
                        type: "success",
                        theme: "light",
                        icon: <CheckCircleOutlineIcon/>
                    });
                loadFoundations();
            },
            foundationId,
            error => {
                toast(`Error: ${error}`,
                    {
                        closeOnClick: true,
                        type: "error",
                        theme: "light",
                        icon: <ErrorOutlineIcon/>
                    });
            })
    }

    const handleModalOpen = (model) => {
        if (model === 'createFoundation') {
            setModelHeader("Create Foundation")
            setModelToOpen(createFoundationForm)
        } else if (model === 'updateFoundation') {
            setModelHeader("Update Foundation")
            setModelToOpen(updateFoundationForm)
        }
        setModalOpen(true);
    }
    const handleModalClose = () => setModalOpen(false);

    const createFoundationForm = (<CreateFoundationForm
        isUpdate={false}
        handleClose={handleModalClose}
        foundationId={null}
        createOrUpdatecallback={() => {
            loadFoundations()
            handleModalClose()
        }}
    />);

    const updateFoundationForm = (<CreateFoundationForm
        isUpdate={true}
        handleClose={handleModalClose}
        foundationId={foundationTobeUpdated}
        createOrUpdatecallback={(data) => {
            loadFoundations()
            handleModalClose()
        }}
    />);

    const floatingActionConfig = [
        {
            title: "Create",
            icon: <AddIcon sx={{mr: 1}}/>,
            onClickAction: handleModalOpen,
            modelName: "createFoundation",
            visible: true,
        }
    ]

    const showDeleteConfirmation = (pid, itmId) => {
        Swal.fire({
            icon: 'warning',
            title: `${itmId}`,
            text: `Are you sure you want to delete this item?`,
            showConfirmButton: true,
            showCancelButton: true,
            footer: 'Please double check before accepting this action'
        }).then((value) => {
            if (value.isConfirmed) {
                deleteFoundationsFunc(pid)
            }
        })
    }

    const tableHeader = (<TableHead>
        <TableRow >
            <TableCell>Collapse</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Action Items</TableCell>
        </TableRow>
    </TableHead>)

    const tableBody = (
        (foundationsList && foundationsList.length > 0) ? (<TableBody>
                {foundationsList.map((foundation, index) => (
                    <FoundationTableRow key={index} row={foundation} deleteItem={showDeleteConfirmation}
                                          setItemTobeUpdated={setFoundationTobeUpdated}
                                          handleModalOpen={handleModalOpen}/>
                ))}
            </TableBody>
        ) : null
    )

    return (
        <PageLayout header="Foundations">
            <Card sx={{padding: 1, margin: 0, border: '1px solid', borderColor: 'rgb(229,226,226)'}}>
                <FloatingActionButtons config={floatingActionConfig}/>
                <MainModalDialog
                    open={modalOpen}
                    handleClose={handleModalClose}
                    header={modelHeader}
                    body={modelToOpen}
                />
                <MainTable
                    iscontentLoading={isFoundationsLoading}
                    colspan={7}
                    tableHeader={tableHeader} tableBody={tableBody}
                />
                <Pagination
                    reloadData={(pageNumber, pageSize) => loadFoundations(pageNumber, pageSize)}
                    pagination={pagination}
                />
            </Card>
        </PageLayout>
    );
}